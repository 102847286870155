import React from "react";
import { Doughnut } from "react-chartjs-2";
import styles from "../../../_css/Chart.module.css";

const CURRENCIES = {
  "EUR": "€",
  "USD": "$",
  "GBP": "£"
}

function DoughnutChart({
  cutout,
  bgColor,
  value,
  totalValue,
  fontSize,
  subValue,
  marginTop,
  isCurrency,
  mainLabel,
  secondaryLabel,
  height
}) {
  const doughnutData = {
    labels: [mainLabel, secondaryLabel],
    datasets: [
      {
        data: [value, totalValue - value],
        backgroundColor: [bgColor, "#CCCCCC"],
      },
    ],
  };

  const options = {
    cutout: cutout, // Adjust to make the doughnut thinner
    plugins: {
      legend: {
        display: false, // Remove the legend
      },
      // Disable datalabels plugin
      datalabels: {
        display: false,
      },
      // tooltip: {
      //   backgroundColor: 'red', // Change this to your desired color
      // }
    },
  };

  return (
    <div
      className={styles.chartWrapper}
      style={{height:height}}
    >
      <div
        style={{
          width: "100%",
          height: "80px",
          position: "absolute",
          top: "50%",
          left: "0",
          lineHeight: "0px",
          textAlign: "center",
          zIndex: -1,
        }}
      >
        <p style={{ height: "80px", fontSize: fontSize }}>
          {isCurrency ? `€${value}` : value}
          {subValue && (
            <span style={{ fontSize: "11px", color: "#898787" }}>
              <br />
              <br />
              {subValue}
            </span>
          )}
        </p>
      </div>
      <Doughnut data={doughnutData} options={options} />
    </div>
  );
}

function DoughnutChartPrice({
  cutout,
  bgColor,
  value,
  totalValue,
  fontSize,
  subValue,
  marginTop,
  isCurrency,
  mainLabel,
  secondaryLabel,
  height,
  minCurrency,
  maxCurrency
}) {
  const doughnutData = {
    labels: [`${mainLabel} (${CURRENCIES[minCurrency]})`, `${secondaryLabel} (${CURRENCIES[maxCurrency]})`],
    datasets: [
      {
        data: [value, totalValue - value],
        backgroundColor: [bgColor, "#CCCCCC"],
      },
    ],
  };

  const options = {
    cutout: cutout, // Adjust to make the doughnut thinner
    plugins: {
      legend: {
        display: false, // Remove the legend
      },
      // Disable datalabels plugin
      datalabels: {
        display: false,
      },
      // tooltip: {
      //   backgroundColor: 'red', // Change this to your desired color
      // }
    },
  };

  return (
    <div
      className={styles.chartWrapper}
      style={{height:height}}
    >
      <div
        style={{
          width: "100%",
          height: "80px",
          position: "absolute",
          top: "50%",
          left: "0",
          lineHeight: "0px",
          textAlign: "center",
          zIndex: -1,
        }}
      >
        <p style={{ height: "80px", fontSize: fontSize }}>
          {isCurrency ? `${CURRENCIES[minCurrency]}${value}` : value}
          {subValue && (
            <span style={{ fontSize: "11px", color: "#898787" }}>
              <br />
              <br />
              {subValue}
            </span>
          )}
        </p>
      </div>
      <Doughnut data={doughnutData} options={options} />
    </div>
  );
}

export { DoughnutChartPrice };
export default DoughnutChart;
