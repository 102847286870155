import "./App.css";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import ProtectedRoutesOutlet from "./_routes/ProtectedRotuesOutlet";
import ProtectedRotues from "./_routes/ProtectedRoutes";
import LoginPage from "./_pages/LoginPage";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { removeUserInformation } from "./_store/reducers/userReducer";
import Loader from "./_components/UI/Loader";
import { Toaster } from "react-hot-toast";
import { Notifications } from "react-push-notification";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import LogoutPage from "./_pages/LogoutPage";
import PushNotifications from "./_components/common/PushNotifications";

// Registering the components with ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

function App() {
  const { accounts } = useMsal();
  const dispatch = useDispatch();

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      const { idToken } = accounts[0];
      localStorage.setItem("aad_token", idToken);
    } else {
      dispatch(removeUserInformation());
      localStorage.removeItem("aad_token");
    }
  }, []);

  return (
    <>
      <Loader />
      <PushNotifications />
      <Notifications />
      <Toaster
        position="top-right"
        containerClassName="hot-toaster-container"
      />
      <Routes>
        <Route path="/" element={<ProtectedRoutesOutlet />}>
          {ProtectedRotues?.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>
        <Route key={`unique_121`} path="/login" element={<LoginPage />}></Route>
        <Route
          key={`unique_122`}
          path="/logout"
          element={<LogoutPage />}
        ></Route>
        <Route path="*" element={<p>404 Not Found!</p>} />
      </Routes>
    </>
  );
}

export default App;
