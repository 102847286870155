// Notifications.js
import React, { useEffect, useRef, useState } from "react";
import styles from "../../_css/Notifications.module.css"; // Assuming you are using CSS modules for additional styles
import { Link, useLocation, useNavigate } from "react-router-dom";
import { notifyError } from "../helper/Toaster";
import { Oval } from "react-loader-spinner";
import { formatDate } from "../helper/formatters";
import { Bell } from "lucide-react";
import { startLoader, stopLoader } from "../../_store/reducers/loaderReducer";
import { useDispatch, useSelector } from "react-redux";
import { updateNotificationStatus } from "../../requests";
import { TOKEN_ERRORS } from "../../_data/codes";
import handleSessionClear from "../helper/handleSessionClear";

const Notifications = () => {
  const notificationRef = useRef();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const getNotifs = useSelector((state) => state.notification);
  const location = useLocation();
  const currentUrl = location.pathname;

  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [unreadNotification, setUnreadNotification] = useState(0);
  const [quickView, setQuickView] = useState(false);

  useEffect(() => {
    setNotifications(getNotifs.notifications);
    setUnreadNotification(getNotifs.unreadNotifications);
  }, [getNotifs]);

  useEffect(() => {
    // Function to handle clicks outside the notification
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setQuickView(false); // Call the setQuickView function passed as a prop
      }
    };

    // Add event listener to document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setQuickView]);

  const notificationClickHandler = async (id, notifId) => {
    await updateNotification(notifId);
    let lastPage = "Notifications";
    if (currentUrl.includes("notification")) {
      lastPage = "Notifications";
    } else if (currentUrl.includes("dashboard")) {
      lastPage = "Dashboard";
    } else if (currentUrl.includes("supplier")) {
      lastPage = "Supplier Information";
    } else if (currentUrl.includes("product-catalogue")) {
      lastPage = "Product Catalogue";
    } else if (currentUrl.includes("offer-management")) {
      lastPage = "Product Offer";
    }
    Navigate(`/offer-management/${id}`, {
      state: { element_id: id, lastPage },
    });
  };

  // GET SUGGESTION DATA FOR FILTER
  const updateNotification = async (notifId) => {
    dispatch(startLoader());

    try {
      const payload = {
        notifId,
        isDelete: false,
        isRead: true,
      };
      const response = await updateNotificationStatus(payload);
      if (response.status === 200) {
        // setAuthFalse();
      }
    } catch (error) {
      notifyError(error.response.data.message);
      if (TOKEN_ERRORS.includes(error?.response?.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  return (
    <div ref={notificationRef}>
      <div
        onClick={() => setQuickView(!quickView)}
        className={`${styles.notification} cursor pe-cursor`}
      >
        <Bell size={18} fill="black" />
        <span className={styles.notificationCount}>{unreadNotification}</span>
      </div>
      {quickView && (
        <div className={`card position-absolute ${styles.notificationCard}`}>
          <div className="card-header bg-white d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Notifications</h5>
            <span className="badge bg-primary">{unreadNotification} New</span>
          </div>
          {!isLoading ? (
            <ul className="list-group list-group-flush">
              {notifications.map((notification, index) => (
                <li
                  onClick={() =>
                    notificationClickHandler(
                      notification.productId,
                      notification._id
                    )
                  }
                  key={index}
                  className={`list-group-item ${styles.notificationItem} ${
                    notification.isNew ? styles.newNotification : ""
                  }`}
                >
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex flex-column">
                      <div className="d-flex align-items-center gap-10">
                        <h6 className={`mb-1}`}>{notification.name}</h6>
                        <span className="fs-11">
                          ({formatDate(notification.createdAt, true)})
                        </span>
                      </div>
                      <p className="mb-1">{notification.message}</p>
                    </div>
                    <small className={`text-muted ${styles.notificationTime}`}>
                      {notification.time}
                    </small>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <Oval
              visible={true}
              height="40"
              width="40"
              color="#4fa94d"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass="justify-content-center p-4"
            />
          )}
          <Link
            /*** onClick={(e) => e.preventDefault()} ***/ to="/notifications"
            className="card-footer bg-white text-center"
          >
            <button className="fs-13 w-100 bg-white m-color-blue btn btn-outline-primary btn-sm">
              See all Notifications
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Notifications;
