import React, { useEffect, useState } from "react";
import styles from "../_css/OfferManagementTwo.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import ProductDetails from "./UI/ProductDetails";
import {
  formatDate,
  getAbvValue,
  getDollarValue,
  getEuroValue,
  getFixedNumber,
  getPoundValue,
} from "./helper/formatters";
import { useDispatch } from "react-redux";
import { startLoader, stopLoader } from "../_store/reducers/loaderReducer";
import { notifyError } from "./helper/Toaster";
import Notifications from "./common/Notifications";
import NotesModal from "./UI/NotesModal";
import { getOfferManagementS2, offerMgmtS1Action } from "../requests";
import { TOKEN_ERRORS } from "../_data/codes";
import handleSessionClear from "./helper/handleSessionClear";
import { setPage } from "../_store/reducers/pageReducer";
import { coloumns2, salesColoumns2 } from "../_data/tableHeaders";

function OfferManagementTwo() {
  const location = useLocation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [conversionRate, setConversionRate] = useState({
    dollarForEuro: 0,
    dollarForPound: 0,
    euroForDollar: 0,
    euroForPound: 0,
    poundForDollar: 0,
    poundForEuro: 0,
  });

  const access_token = localStorage.getItem("access_token_MN");
  const element_id = location.state?.element_id;
  const lastPage = location.state?.lastPage;

  const isAdmin =
    JSON.parse(localStorage.getItem("user_info_MN"))?.status === 2;

  // useEffect(() => {
  //   const item = location.state?.item;
  //   if (!item) Navigate("/");
  //   else setData([item]);
  // }, []);

  useEffect(() => {
    if (access_token && element_id) {
      getOffer();
      const payload = {
        currentPage: "/offer-management",
        previousPage: lastPage,
      };
      dispatch(setPage(payload));
    } else if (access_token && !element_id) {
      Navigate("/product-catalogue");
    } else {
      Navigate("/login");
    }
  }, [element_id]);

  const handleShowModal = (value) => {
    const reqData = {
      actionType: value,
      data: data[0],
    };

    setSelectedAction(reqData);
    setShowModal(!showModal);
  };

  const handleNotesUpdate = async (
    note,
    actionData,
    isUpdate = false, // if edit functionality then set it to true
    isDelete = false
  ) => {
    setShowModal(false);
    dispatch(startLoader());

    try {
      const payload = {
        actionType: actionData?.actionType,
        quantity: actionData?.quantity,
        note: note,
        productId: data[0]._id,
        isUpdate: isUpdate,
        noteId: actionData,
        status: isDelete ? 2 : 1,
      };
      const response = await offerMgmtS1Action(payload);

      if (response.status === 200) {
        await getOffer();
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  const getOffer = async () => {
    dispatch(startLoader());

    try {
      const payload = {
        element_id: element_id,
      };
      const response = await getOfferManagementS2(payload);
      if (response.status !== 200) {
        // setAuthFalse();
      }
      setConversionRate(response?.data?.conversionRate);
      setData([response?.data?.products]);
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  return (
    <div className={`container mt-4 ${styles.productCatalogue}`}>
      <div className={`position-relative ${styles.filterSection}`}>
        <div className={styles.container}>
          <div className={`${styles.header} d-flex flex-row`}>
            <div className="col-8">
              <h1 className={styles.title}>Offer Management</h1>
            </div>
            <div className={`${styles.filters} col-4`}>
              <Notifications />
            </div>
          </div>
        </div>
      </div>
      <div className={styles["table-wrapper"]}>
        <div className={`${styles.table} overflow-auto`}>
          <table className="table">
            <thead>
              <tr>
                {(isAdmin ? coloumns2 : salesColoumns2).map((e, index) => (
                  <th key={index}>{e}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{formatDate(item.date)}</td>
                  <td>{item.category}</td>

                  {isAdmin && <td>{item.supplierName}</td>}
                  <td>{item.sCode}</td>
                  {/* <td>{item.mfCode}</td> */}

                  <td>{item.itemName}</td>
                  <td>{item.pack}</td>
                  <td>{item.size}</td>
                  <td>{getAbvValue(item?.abv)}</td>
                  <td>{item.giftBoxOrNoGiftBox}</td>

                  <td>{item.refNref}</td>
                  <td>{item.codedOrDecoded}</td>
                  <td>{item.btlsAvail}</td>
                  <td>{item.csAvail}</td>

                  <td>
                    {getEuroValue(
                      item.pricePerCaseEuro,
                      item.currency,
                      conversionRate
                    )}
                  </td>
                  <td>
                    {getDollarValue(
                      item.pricePerCaseEuro,
                      item.currency,
                      conversionRate
                    )}
                  </td>
                  <td>
                    {getPoundValue(
                      item.pricePerCaseEuro,
                      item.currency,
                      conversionRate
                    )}
                  </td>
                  <td>
                    {getEuroValue(
                      item.pricePerBottleEuro,
                      item.currency,
                      conversionRate
                    )}
                  </td>
                  <td>
                    {getDollarValue(
                      item.pricePerBottleEuro,
                      item.currency,
                      conversionRate
                    )}
                  </td>
                  <td>
                    {getPoundValue(
                      item.pricePerBottleEuro,
                      item.currency,
                      conversionRate
                    )}
                  </td>
                  <td>{item.status}</td>
                  <td>{item.leadTime}</td>
                  <td>{item.location}</td>
                  {/* <td>{item.onFloor}</td>

                  <td>{item.incoterms}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {data.length > 0 && data[0].notes.length > 0 && (
        <div className="d-flex justify-content-between row">
          <div className={`col-10`}>
            <ProductDetails
              handleNotesUpdate={handleNotesUpdate}
              notes={data[0]?.notes || []}
              quantity={data[0]?.casesRequested}
            />
          </div>

          <div className={`d-flex flex-column col-2 ${styles.buttons}`}>
            {/* <Button
                handleButtonClick={() => handleShowModal("Buy")}
                title={`Buy`}
              /> */}
            <div className={`height-3r`}>
              <div className={`${styles.table} overflow-auto`}>
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isAdmin && (
                      <tr>
                        <td
                          className={`text-center pe-cursor`}
                          onClick={() => handleShowModal("Purchase")}
                        >
                          <span
                            className={`d-flex justify-content-center ${styles.status} ${styles.statusPurchased}`}
                          >
                            Purchase
                          </span>
                        </td>
                      </tr>
                    )}
                    {isAdmin && (
                      <tr>
                        <td
                          className={`text-center pe-cursor`}
                          onClick={() => handleShowModal("Reject")}
                        >
                          <span
                            className={`d-flex justify-content-center ${styles.status} ${styles.statusRejected}`}
                          >
                            Reject
                          </span>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td
                        className={`text-center pe-cursor`}
                        onClick={() => handleShowModal("Add")}
                      >
                        <span
                          className={`d-flex justify-content-center ${styles.status} ${styles.statusAddNotes}`}
                        >
                          Add Notes
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* <Button
                handleButtonClick={() => handleShowModal("Purchase")}
                title={`Purchase`}
              />
              <Button
                handleButtonClick={() => handleShowModal("Reject")}
                title={`Reject`}
              /> */}
          </div>
          {/* <ProductStatus /> */}
        </div>
      )}
      <NotesModal
        selectedAction={selectedAction}
        showModal={showModal}
        handleShowModal={handleShowModal}
        handleContinue={handleNotesUpdate}
        title={"Add Note"}
        type={isAdmin ? "admin" : "sales"}
        quantity={data[0]?.casesRequested}
      />
    </div>
  );
}

export default OfferManagementTwo;
