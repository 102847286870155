import React from "react";
import { FallingLines } from "react-loader-spinner";
import { useSelector } from "react-redux";

function Loader() {
  const isLoading = useSelector(state => state.loader.isLoading)
  return (
    <div style={{
      display: isLoading ? "flex" : "none",
      zIndex: 999999999999,
      opacity: 0.6,
    }} className="loader">
      <FallingLines
        color="#4339f2"
        width="100"
        visible={true}
        ariaLabel="falling-circles-loading"
      />
    </div>
  );
}

export default Loader;
