import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles2 from "../../../_css/NotesModal.module.css";
import _ from "lodash";
import { useDispatch } from "react-redux";
import {
  startLoader,
  stopLoader,
} from "../../../_store/reducers/loaderReducer";
import { updateDollarRate } from "../../../requests";
import handleSessionClear from "../../helper/handleSessionClear";
import { useNavigate } from "react-router-dom";
import styles from "../../../_css/AdminPanel.module.css";
import { notifyError, notifySuccess } from "../../helper/Toaster";
import { TOKEN_ERRORS } from "../../../_data/codes";
import { TooltipRates } from "../../helper/Tooltip";

function ConversionModal({
  conversionRate: cr,
  handleShowModal,
  showModal,
  getUsers,
}) {
  const [show, setShow] = useState(showModal);
  const [conversionRate, setConversionRates] = useState(cr);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (cr && (!conversionRate || !conversionRate?.dollarForEuro)) {
      setConversionRates(cr);
    }
    setShow(showModal);
  }, [cr, showModal]);

  const handleUpdatePrice = async () => {
    if (!conversionRate?.dollarForEuro || conversionRate?.dollarForEuro <= 0) {
      notifyError("Dollar Rate Should be A Positive Number (not less than 0)");
      return;
    } else if (
      !conversionRate?.euroForDollar ||
      conversionRate?.euroForDollar <= 0
    ) {
      notifyError("Euro Rate Should be A Positive Number (not less than 0)");
      return;
    } else if (
      !conversionRate?.poundForDollar ||
      conversionRate?.poundForDollar <= 0
    ) {
      notifyError("Pound Rate Should be A Positive Number (not less than 0)");
      return;
    } else if (
      !conversionRate?.euroForPound ||
      conversionRate?.euroForPound <= 0
    ) {
      notifyError("Euro Rate Should be A Positive Number (not less than 0)");
      return;
    } else if (
      !conversionRate?.poundForEuro ||
      conversionRate?.poundForDollar <= 0
    ) {
      notifyError("Pound Rate Should be A Positive Number (not less than 0)");
      return;
    } else if (
      !conversionRate?.dollarForPound ||
      conversionRate?.dollarForPound <= 0
    ) {
      notifyError("Dollar Rate Should be A Positive Number (not less than 0)");
      return;
    }

    dispatch(startLoader());

    try {
      const payload = conversionRate;
      const response = await updateDollarRate(payload);

      if (response.status === 200) {
        notifySuccess(response.data.message);
        await getUsers();
        handleShowModal();
      }
    } catch (error) {
      notifyError(error.response.data.message);
      if (TOKEN_ERRORS.includes(error?.response?.status)) {
        handleSessionClear();
        navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  const getNameValue = (name) => {
    let name2 = "";
    if (name === "dollarForEuro") {
      name2 = "euroForDollar";
    } else if (name === "euroForDollar") {
      name2 = "dollarForEuro";
    } else if (name === "dollarForPound") {
      name2 = "poundForDollar";
    } else if (name === "poundForDollar") {
      name2 = "dollarForPound";
    } else if (name === "poundForEuro") {
      name2 = "euroForPound";
    } else if (name === "euroForPound") {
      name2 = "poundForEuro";
    }

    return name2;
  };

  const handlePriceChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    const name2 = getNameValue(name);
    const value2 = (1 / value).toFixed(2);

    setConversionRates((prevValue) => ({
      ...prevValue,
      [name]: value,
      [name2]: value2,
    }));
  };

  // const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleShowModal} centered>
        {/* <Modal.Header className="m-auto">
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header> */}
        <Modal.Body
          className={`gap-5px mx-1 d-flex justify-content-center flex-column ${styles2["notes-container"]}`}
        >
          <div className="d-flex gap-5px mt-1 justify-content-around">
            <div>
              <div className="d-flex flex-column gap-1 mt-2">
                <label className={`${styles["input-label"]}`}>
                  Dollar Rate (Euro){" "}
                  <TooltipRates price_1="Dollar" price_2="Euro" />
                </label>
                <input
                  type="number"
                  className={`${styles.qtyInput}`}
                  id="quantityField"
                  aria-describedby="quantity"
                  placeholder="Enter Dollar Rate"
                  onChange={handlePriceChange}
                  value={conversionRate?.dollarForEuro}
                  name="dollarForEuro"
                />
              </div>
              <div className="d-flex flex-column gap-1 mt-2">
                <label className={`${styles["input-label"]}`}>
                  Dollar Rate (Pound){" "}
                  <TooltipRates price_1="Dollar" price_2="Pound" />
                </label>
                <input
                  type="number"
                  className={`${styles.qtyInput}`}
                  id="quantityField"
                  aria-describedby="quantity"
                  placeholder="Enter Dollar Rate"
                  onChange={handlePriceChange}
                  value={conversionRate?.dollarForPound}
                  name="dollarForPound"
                />
              </div>
              <div className="d-flex flex-column gap-1 mt-2">
                <label className={`${styles["input-label"]}`}>
                  Euro Rate (Pound){" "}
                  <TooltipRates price_1="Euro" price_2="Pound" />
                </label>
                <input
                  type="number"
                  className={`${styles.qtyInput}`}
                  id="quantityField"
                  aria-describedby="quantity"
                  placeholder="Enter Euro Rate"
                  onChange={handlePriceChange}
                  value={conversionRate?.euroForPound}
                  name="euroForPound"
                />
              </div>
            </div>
            <div>
              <div className="d-flex flex-column gap-1 mt-2">
                <label className={`${styles["input-label"]}`}>
                  Euro Rate (Dollar){" "}
                  <TooltipRates price_1="Euro" price_2="Dollar" />
                </label>
                <input
                  type="number"
                  className={`${styles.qtyInput}`}
                  id="quantityField"
                  aria-describedby="quantity"
                  placeholder="Enter Euro Rate"
                  onChange={handlePriceChange}
                  value={conversionRate?.euroForDollar}
                  name="euroForDollar"
                />
              </div>
              <div className="d-flex flex-column gap-1 mt-2">
                <label className={`${styles["input-label"]}`}>
                  Pound Rate (Dollar){" "}
                  <TooltipRates price_1="Pound" price_2="Dollar" />
                </label>
                <input
                  type="number"
                  className={`${styles.qtyInput}`}
                  id="quantityField"
                  aria-describedby="quantity"
                  placeholder="Enter Pound Rate"
                  onChange={handlePriceChange}
                  value={conversionRate?.poundForDollar}
                  name="poundForDollar"
                />
              </div>

              <div className="d-flex flex-column gap-1 mt-2">
                <label className={`${styles["input-label"]}`}>
                  Pound Rate (Euro){" "}
                  <TooltipRates price_1="Pound" price_2="Euro" />
                </label>
                <input
                  type="number"
                  className={`${styles.qtyInput}`}
                  id="quantityField"
                  aria-describedby="quantity"
                  placeholder="Enter Pound Rate"
                  onChange={handlePriceChange}
                  value={conversionRate?.poundForEuro}
                  name="poundForEuro"
                />
              </div>
            </div>
            {/* <div
              style={{
                position: "relative",
              }}
              onClick={handleUpdatePrice}
              className={styles.statusContainer}
            >
              <span
                className={`fw-500 pe-cursor ${styles["price-button"]} ${styles.status} ${styles["view-offers"]}`}
              >
                Save
              </span>
            </div> */}
            {/* <small id="emailHelp" class="form-text text-muted">
              We'll never share your email with anyone else.
            </small> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-top-0 d-flex">
          <div
            style={{
              position: "relative",
            }}
            onClick={handleShowModal}
            className={styles.statusContainer}
          >
            <span
              className={`fw-500 pe-cursor ${styles["cancel-button"]} ${styles.status} ${styles["view-offers"]}`}
            >
              Cancel
            </span>
          </div>
          <div
            style={{
              position: "relative",
            }}
            onClick={handleUpdatePrice}
            className={styles.statusContainer}
          >
            <span
              className={`fw-500 pe-cursor ${styles["price-button"]} ${styles.status} ${styles["view-offers"]}`}
            >
              Save
            </span>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConversionModal;
