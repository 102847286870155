// src/AdminPanel.js
import React, { useEffect, useState } from "react";
import styles from "../_css/AdminPanel.module.css";
import { useNavigate } from "react-router-dom";
import Paginate from "./UI/Pagination";
import { startLoader, stopLoader } from "../_store/reducers/loaderReducer";
import { useDispatch } from "react-redux";
import { notifyError, notifySuccess } from "./helper/Toaster";
import { formatDate } from "./helper/formatters";
import SelectComponent from "./common/SelectComponent";
import _ from "lodash";
import { ChevronDown, ChevronUp } from "lucide-react";
import PushNotifications from "./common/PushNotifications";
import {
  getUserFilterSearch,
  getUsersList,
  updateDollarRate,
  updateUserStatus,
} from "../requests";
import { TOKEN_ERRORS } from "../_data/codes";
import handleSessionClear from "./helper/handleSessionClear";
import ConversionModal from "./UI/Models/ConversionModal";

const COLUMN_NAMES = ["Name", "Email", "Signup Time", "User Status"];
const SORTABLE_COLUMNS = ["Name", "Email", "Signup Time"];

const AdminPanel = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    totalPages: 1,
    totalPosts: 0,
  });
  const [sortedColumn, setSortedColumn] = useState({
    column: String,
    sortDsc: true,
  });

  const [options, setOptions] = useState([]);
  const [suggestedData, setSuggestionData] = useState([]);
  const [loadingSuggestion, setLoadingSuggestion] = useState(false);
  const [sortingData, setSortingData] = useState(null);
  const [perPage, setPerPage] = useState(5);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [conversionRate, setConversionRates] = useState({
    dollarForEuro: 0,
    dollarForPound: 0,
    euroForDollar: 0,
    euroForPound: 0,
    poundForDollar: 0,
    poundForEuro: 0,
  });
  const [showModal, setShowModal] = useState(false);
  const [dataSorting, setDataSorting] = useState({
    createdAt: -1
  })

  const access_token = localStorage.getItem("access_token_MN");

  useEffect(() => {
    if (access_token) {
      getUsers();
    } else {
      Navigate("/login");
    }
  }, [page, sortingData, perPage, dataSorting]);

  const getUsers = async () => {
    dispatch(startLoader());

    // CHECK IF SORTING
    let isSorting = false;
    if (sortingData) {
      isSorting = true;
    }

    try {
      const payload = {
        page,
        isSorting,
        limit: perPage || 5,
        sortingData,
        dataSorting
      };

      const response = await getUsersList(payload);
      if (response.status !== 200) {
      }

      const pageInfo = {
        totalPages: response.data.totalPages || 1,
        totalPosts: response.data.totalPosts || 0,
      };

      setUsers(response?.data?.posts);
      setConversionRates(response?.data?.currencyConversionRate);

      setPageInfo(pageInfo);
      setSuggestionData(response?.data?.suggestedData);
    } catch (error) {
      notifyError(error.response.data.message);
      if (TOKEN_ERRORS.includes(error.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleNext = async (page) => {
    if (page + 1 <= pageInfo.totalPages) {
      setPage(page + 1);
    }
  };

  const handleResetPage = () => {
    setPage(1)
  }

  const handlePrevious = async (page) => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const handlePerPage = (count) => {
    setPerPage(count);
  };

  const getCheveron = (key) => {
    let lowerKey = _.toLower(key);
    if (lowerKey === "signup time") lowerKey = "createdAt";

    return (
      <i>
        {Object.keys(dataSorting)[0] === lowerKey ? (
          dataSorting[lowerKey] === -1 ? (
            <ChevronUp size={16} />
          ) : (
            <ChevronDown size={16} />
          )
        ) : (
          <ChevronUp size={16} />
        )}
      </i>
    );
  };

  const handleSort = (key) => {
    let lowerKey = _.toLower(key);
    if (lowerKey === "signup time") lowerKey = "createdAt";

    console.log(lowerKey)
    setDataSorting((prevValue) => ({
      [lowerKey]: prevValue[lowerKey] === 1 ? -1 : 1,
    }));
    // let previousLowerKey = !_.isEmpty(sortedColumn.column)
    //   ? _.toLower(sortedColumn.column)
    //   : null;

    // if (previousLowerKey === "signup time") previousLowerKey = "createdAt";
    // let sortedData = users;
    // let coloumnChanged = false;

    // if (previousLowerKey && lowerKey !== previousLowerKey) {
    //   coloumnChanged = true;
    //   sortedData = [...sortedData].sort((a, b) => {
    //     if (a[previousLowerKey] < b[previousLowerKey])
    //       return sortedColumn.sortDsc ? -1 : 1;
    //     if (a[previousLowerKey] > b[previousLowerKey])
    //       return sortedColumn.sortDsc ? 1 : -1;
    //     return 0;
    //   });
    // }

    // const sortingBasis = coloumnChanged ? coloumnChanged : sortedColumn.sortDsc;
    // sortedData = [...sortedData].sort((a, b) => {
    //   if (a[lowerKey] < b[lowerKey]) return sortingBasis ? -1 : 1;
    //   if (a[lowerKey] > b[lowerKey]) return sortingBasis ? 1 : -1;
    //   return 0;
    // });

    // setSortedColumn({ column: key, sortDsc: !sortingBasis });
    // setUsers(sortedData);
  };

  // GET SUGGESTION DATA FOR FILTER
  const getSuggestionData = async (inputColumn, inputValue) => {
    if (_.isEmpty(inputColumn) || _.isEmpty(inputValue)) return;

    setLoadingSuggestion(true);
    try {
      const payload = {
        searchFor: inputColumn,
        searchString: inputValue,
      };
      const response = await getUserFilterSearch(payload);

      if (response.status === 200) {
        const { formattedData } = response.data;

        if (inputColumn === "name") {
          setSuggestionData((prevData) => {
            return {
              ...prevData,
              suggestionDataEmail: formattedData,
            };
          });
        } else {
          setSuggestionData((prevData) => {
            return {
              ...prevData,
              suggestionDataNames: formattedData,
            };
          });
        }
        // setAuthFalse();
      }
    } catch (error) {
      notifyError(error.response.data.message);
      if (TOKEN_ERRORS.includes(error?.response?.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      setLoadingSuggestion(false);
    }
  };

  const handleValueChange = async (value, column) => {
    if (value && value?.length > 0 && column) {
      let values = [];
      for (const data of value) {
        values.push(data.value);
      }
      const reqData = {
        value: values,
        column: column,
      };

      setSortingData(reqData);
      // await getUsers(true, reqData);
    } else {
      setSortingData(null);
    }
    handleResetPage()
  };

  const clearValueHandler = () => {
    // handleResetPage()
    setOptions([]);
    setSortingData(null);
  };

  const handleStatusChange = async (event, userId) => {
    event.preventDefault();
    dispatch(startLoader());

    try {
      const payload = {
        status: event.target.value,
        user: userId,
      };

      const response = await updateUserStatus(payload);

      if (response.status !== 200) {
        await getUsers();
        notifySuccess("Status Updated Successfully.");
      }
    } catch (error) {
      notifyError(error.response.data.message);
      if (TOKEN_ERRORS.includes(error?.response?.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleUpdatePrice = async () => {
    if (!conversionRate.dollarForEuro || conversionRate.dollarForEuro <= 0) {
      notifyError("Dollar Rate Should be A Positive Number (not less than 0)");
      return;
    } else if (!conversionRate.euroForDollar || conversionRate.euroForDollar <= 0) {
      notifyError("Euro Rate Should be A Positive Number (not less than 0)");
      return;
    } else if (!conversionRate.poundForDollar || conversionRate.poundForDollar <= 0) {
      notifyError("Pound Rate Should be A Positive Number (not less than 0)");
      return;
    } else if (!conversionRate.euroForPound || conversionRate.euroForPound <= 0) {
      notifyError("Euro Rate Should be A Positive Number (not less than 0)");
      return;
    } else if (!conversionRate.poundForEuro || conversionRate.poundForDollar <= 0) {
      notifyError("Pound Rate Should be A Positive Number (not less than 0)");
      return;
    } else if (!conversionRate.dollarForPound || conversionRate.dollarForPound <= 0) {
      notifyError("Dollar Rate Should be A Positive Number (not less than 0)");
      return;
    }

    dispatch(startLoader());

    try {
      const payload = conversionRate;
      const response = await updateDollarRate(payload);

      if (response.status === 200) {
        notifySuccess(response.data.message);
      }
    } catch (error) {
      notifyError(error.response.data.message);
      if (TOKEN_ERRORS.includes(error?.response?.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  const handlePriceChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    setConversionRates((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const onFocusHandler = async (column) => {
    clearValueHandler();
    setSelectedFilter(column);
    // if (column === "size") {
    //   if (!sortingData?.value || _.isEmpty(sortingData?.value)) {
    //     notifyInfo("Please select Description First!");
    //     return;
    //   }
    //   await getSuggestionData("size", alcoholInfo?.name, true);
    // } else if (column === "name") {
    //   await getSuggestionData(column);
    // }
  };

  return (
    <div className={`ml-5rem container ${styles.adminPanel}`}>
      <PushNotifications />
      <div className="row mb-3">
        <div className="col">
          <h1 className="text-primary">Millennium</h1>
          <div className="d-flex gap-5px mt-1">
            <div
              style={{
                position: "relative",
              }}
              onClick={() => setShowModal(true)}
              className={styles.statusContainer}
            >
              <span
                className={`fw-500 pe-cursor ${styles["price-button"]} ${styles.status} ${styles["view-offers"]}`}
              >
                Update Currency Rate
              </span>
            </div>
            {/* <small id="emailHelp" class="form-text text-muted">
              We'll never share your email with anyone else.
            </small> */}
          </div>
        </div>
        <div className="col text-right d-flex gap-3 justify-content-end align-items-center">
          {/* <input
            type="text"
            className="form-control w-50"
            placeholder="Search"
          /> */}
          <SelectComponent
            options={suggestedData?.suggestionDataEmail}
            placeholder={"Email"}
            handleValueChange={handleValueChange}
            getSuggestionData={getSuggestionData}
            clearValueHandler={clearValueHandler}
            onFocusHandler={onFocusHandler}
            inputColumn={"email"}
            loadingSuggestion={loadingSuggestion}
            selectedFilter={selectedFilter}
          />
          <SelectComponent
            options={suggestedData?.suggestionDataNames}
            placeholder={"Name"}
            handleValueChange={handleValueChange}
            getSuggestionData={getSuggestionData}
            clearValueHandler={clearValueHandler}
            onFocusHandler={onFocusHandler}
            inputColumn={"name"}
            selectedFilter={selectedFilter}
            loadingSuggestion={loadingSuggestion}
          />
        </div>
      </div>
      <div className={`rounded position-relative table-height-400 p-1 ${styles.table}`}>
        <table className="table">
          <thead>
            <tr>
              {COLUMN_NAMES.map((element, index) => (
                <th
                  key={index}
                  className="pe-cursor"
                  onClick={() => handleSort(element)}
                  scope="col"
                  style={{
                    width: element === "User Status" ? "15rem" : "inherit",
                  }}
                >
                  <div className={`${styles["col-value"]}`}>
                    <span>{element}</span>
                    {SORTABLE_COLUMNS.includes(element) && (
                      getCheveron(element)
                    )}
                  </div>
                </th>
              ))}
              {/* <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Signup Time</th>
              <th scope="col">User Status</th> */}
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{formatDate(user.createdAt)}</td>
                <td>
                  <select
                    className="form-select"
                    //   value={user.status}
                    style={{ fontSize: "13px", color: "rgb(114 114 114)" }}
                    disabled={user.status === 1}
                    onChange={(event) => handleStatusChange(event, user._id)}
                  >
                    <option selected={user.status === 0} value={0}>
                      Pending
                    </option>
                    <option selected={user.status === 2} value={2}>
                      Procurement/ Admin
                    </option>
                    <option selected={user.status === 3} value={3}>
                      Sales
                    </option>
                    <option selected={user.status === 9} value={9}>
                      Block
                    </option>
                    {user.status === 1 && (
                      <option selected={user.status === 1} value={1} disabled>
                        Super Admin
                      </option>
                    )}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-3">
        <Paginate
          currentPage={page}
          pageInfo={pageInfo}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          handlePerPage={handlePerPage}
          resetPage={handleResetPage}
        />
      </div>
      <ConversionModal 
         showModal={showModal}
         handleShowModal={() => setShowModal(!showModal)}
         conversionRate={conversionRate}
         getUsers={getUsers}
      />
    </div>
  );
};

export default AdminPanel;
