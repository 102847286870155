import React from "react";
import { Search } from "lucide-react";
import styles from "../../_css/SearchInput.module.css"; // Create a CSS module for styling

const SearchInput = ({ value, onChange, placeholder, onEnterClick }) => {
  return (
    <div className={styles.searchContainer}>
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={styles.searchInput}
        onKeyDown={onEnterClick}
      />
      <Search size={16} className={styles.searchIcon} />
    </div>
  );
};

export default SearchInput;
