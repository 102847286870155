import React from "react";
import styles from "../../_css/Pending.module.css";

function Pending() {
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${styles.container}`}
    >
      <div className={`${styles['fixed-width']} card text-center p-4 shadow`}>
        <div className={`mb-4 ${styles.iconWrapper}`}>
          <img
            src={`${process.env.REACT_APP_PROJECT_URL}/img/check1.png`}
            alt="Checkmark"
            className={`${styles.icon}`}
          />
        </div>
        <h1 className="h4 fw-700">Thanks for login to Millennium</h1>
        <p className={`${styles['text-info']} text-muted`}>
          Your profile is currently in review. We’ll get back to you soon.
        </p>
      </div>
    </div>
  );
}

export default Pending;
