// Define initial state
const initialState = {
    preserveState: null,
    location: null,
  };
  
  // Define action types
  const PRESERVE_STATE = "PRESERVE_STATE";
  const CLEAR_STATE = "CLEAR_STATE";
  
  // Define reducer function
  const preserveStateReducer = (state = initialState, action) => {
    switch (action.type) {
      case PRESERVE_STATE:
        return {
          ...state,
          preserveState: action.payload.preserveState,
          location: action.payload.location,
        };
      case CLEAR_STATE:
        return {
          ...state,
          preserveState: null,
          location: null,
        };
      default:
        return state;
    }
  };
  
  // Action creators
  const preserveState = (payload) => ({
    type: PRESERVE_STATE,
    payload: payload,
  });

  const clearState = (payload) => ({
    type: CLEAR_STATE,
    payload: payload,
  });
  
  
  export { preserveState, clearState };
  export default preserveStateReducer;
  