// Dashboard.js
import React, { useEffect, useState } from "react";
import styles from "../_css/Dashboard.module.css";
import BarChart from "../_components/UI/Charts/BarChart";
import SelectComponent from "../_components/common/SelectComponent";
import Notifications from "../_components/common/Notifications";
import HorizontalBarChart from "../_components/UI/Charts/HorizontalBarChart";
import DoughnutChart, {
  DoughnutChartPrice,
} from "../_components/UI/Charts/Doughnut";
import { useDispatch } from "react-redux";
import { startLoader, stopLoader } from "../_store/reducers/loaderReducer";
import { notifyError, notifyInfo } from "../_components/helper/Toaster";
import { useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import _ from "lodash";
import DataLoader from "../_components/common/DataLoader";
import PieChart from "../_components/UI/Charts/PieChart";
import {
  getDashboardReports,
  getDashboardReportsEnquiries,
  getDashboardReportsSearch,
} from "../requests";
import { TOKEN_ERRORS } from "../_data/codes";
import handleSessionClear from "../_components/helper/handleSessionClear";
import {
  formatDate,
  getDollarValue,
  getEuroValue,
  getFixedNumber,
  getPoundValue,
  numbersFormatter,
} from "../_components/helper/formatters";
import { OverlayTrigger } from "react-bootstrap";
import { tooltipGetSupplier, tooltipShowOffer } from "../_components/helper/Tooltip";

const Dashboard = () => {
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [sortingData, setSortingData] = useState();
  const [loadingSuggestion, setLoadingSuggestion] = useState(false);
  const [loadingDescription, setLoadingDescription] = useState(false);
  const [offersCount, setOffersCount] = useState({
    total: 0,
    current: 0,
  });
  const [data, setData] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const [inquiries, setInquiries] = useState([]);
  const [filteredInquiries, setFilteredInquiries] = useState({
    resolvedInquiries: 0,
    pendingInquiries: 0,
  });
  const [loadingInquiries, setLoadingInquiries] = useState(false);
  const [totalValue, setTotalValue] = useState(0);
  const [cheapestAlcohol, setCheapestAlcohol] = useState([]);
  const [mostExpensiveAlcohol, setMostExpensiveAlcohol] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [defaultValues, setDefaultValues] = useState(false);
  const [conversionRate, setConversionRate] = useState({
    dollarForEuro: 0,
    dollarForPound: 0,
    euroForDollar: 0,
    euroForPound: 0,
    poundForDollar: 0,
    poundForEuro: 0,
  });
  const [alcoholInfo, setAlcoholInfo] = useState({
    name: "",
    size: 0,
  });
  const [startDate, endDate] = dateRange;

  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const isAdmin =
    JSON.parse(localStorage.getItem("user_info_MN"))?.status === 2;
  const access_token = localStorage.getItem("access_token_MN");

  // USE EFFECTS
  useEffect(() => {
    if (access_token && sortingData?.value && sortingData?.value2) {
      getReports();
    } else if (!access_token) {
      Navigate("/login");
    }
  }, [sortingData, access_token, Navigate]);

  useEffect(() => {
    if (access_token && !sortingData?.value && !sortingData?.value2) {
      getReports("itemName");
    } else if (!access_token) {
      Navigate("/login");
    }
  }, [access_token, Navigate, sortingData]);

  useEffect(() => {
    getTotalValue();
  }, [mostExpensiveAlcohol]);

  useEffect(() => {
    if (access_token) {
      getInquiries();
    } else {
      Navigate("/login");
    }
  }, []);

  const getReports = async () => {
    dispatch(startLoader());

    try {
      const payload = {
        sortingData,
      };
      const response = await getDashboardReports(payload);

      if (response.status !== 200) {
        // setAuthFalse();
      }

      if (!sortingData?.column && response?.data?.alcoholInfo?.name) {
        setSortingData((prevValue) => {
          return {
            ...prevValue,
            column: "itemName",
            value: [response?.data?.alcoholInfo?.name],
          };
        });
      }

      if (!sortingData?.column2 && response?.data?.alcoholInfo?.size) {
        setSortingData((prevValue) => {
          return {
            ...prevValue,
            column2: "size",
            value2: [response?.data?.alcoholInfo?.size],
          };
        });
      }

      setData(response?.data?.products);
      setStatsData(response?.data?.statsProducts);
      setCheapestAlcohol(response?.data?.cheapestAlcohol);
      setMostExpensiveAlcohol(response?.data?.mostExpensiveAlcohol);
      setOffersCount({
        total: response?.data?.totalProducts,
        current: response?.data?.totalAlcohols,
      });
      setFilteredInquiries({
        pendingInquiries: response?.data?.inquiriesPending,
        resolvedInquiries: response?.data?.inquiriesResolved,
      });
      setAlcoholInfo(response?.data?.alcoholInfo);
      setConversionRate(response?.data?.conversionRate);
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  const getInquiries = async () => {
    // DON'T FETCH DATA IF ITEM DESCRIPTION IS NOT AVAILABLE
    setLoadingInquiries(true);

    try {
      const payload = {
        sortingData,
      };
      const response = await getDashboardReportsEnquiries(payload);

      if (response.status !== 200) {
        // setAuthFalse();
      }

      setInquiries(response?.data?.products);
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      setLoadingInquiries(false);
    }
  };

  // GET SUGGESTION DATA FOR FILTER
  const getSuggestionData = async (
    inputColumn,
    inputValue,
    sizeData = false
  ) => {
    if (!inputColumn && !inputValue) return;

    setLoadingSuggestion(true);

    if (inputColumn === "itemName") {
      setLoadingDescription(true);
    }
    try {
      const payload = {
        searchFor: inputColumn,
        searchString: inputValue,
        sizeData,
      };

      const response = await getDashboardReportsSearch(payload);

      if (response.status === 200) {
        const { formattedData } = response.data;

        if (inputColumn === "itemName") {
          setOptions(formattedData);
        }

        if (sizeData) {
          setOptions2(formattedData);
        }

        // setAuthFalse();
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      setLoadingSuggestion(false);
      setLoadingDescription(false);
    }
  };

  const handleDateFilter = async (selectedDateRange) => {
    setDateRange(selectedDateRange);

    const [startDate, endDate] = selectedDateRange;

    if (startDate && endDate) {
      setSortingData({
        ...sortingData,
        column3: "date",
        value3: selectedDateRange,
      });
      setDefaultValues(false);
    } else {
      let newValues = sortingData;
      delete newValues?.column3;
      delete newValues?.value3;
      setSortingData(newValues);
      setDefaultValues(false);
    }
  };

  const handleValueChange = async (value, column) => {
    if (value && column) {
      let values = [];
      values.push(value.value);
      // for (const data of value) {
      //   values.push(data.value);
      // }

      let reqData = {};

      if (column === "itemName") {
        reqData = {
          value: values,
          column: column,
        };
        // setAlcoholInfo((prevState) => {
        //   return {
        //     name: value.value,
        //   };
        // });
      } else {
        reqData = {
          ...sortingData,
          value2: values,
          column2: column,
        };
        // setAlcoholInfo((prevState) => {
        //   return {
        //     ...prevState,
        //     size: value.value,
        //   };
        // });
      }
      setSortingData(reqData);
      // await getOffers(true, reqData);
    }
  };

  const clearValueHandler = () => {
    // setOptions([]);
    // setSortingData(null);
    // setDateRange([null, null]);
  };

  const onFocusHandler = async (column) => {
    if (column === "size") {
      if (!sortingData?.value || _.isEmpty(sortingData?.value)) {
        notifyInfo("Please select Description First!");
        return;
      }
      let value = sortingData?.value;
      if (_.isArray(sortingData?.value)) {
        value = sortingData?.value[0];
      }

      await getSuggestionData("size", value, true);
    } else if (column === "itemName") {
      await getSuggestionData(column);
    }
  };

  const getTotalValue = () => {
    const value1 =
      mostExpensiveAlcohol?.length > 0
        ? mostExpensiveAlcohol[0]?.pricePerBottleEuro
        : 0;
    const value2 =
      cheapestAlcohol?.length > 0 ? cheapestAlcohol[0]?.pricePerBottleEuro : 0;

    const totalValue = parseFloat(value1) + parseFloat(value2);
    setTotalValue(totalValue.toFixed(2));
  };

  const handleResetFilters = () => {
    setSortingData(null);
    setDateRange([null, null]);
    // getReports()
  };

  return (
    <div className={`container ${styles.dashboard}`}>
      <div className={`position-relative mb-4 ${styles.filterSection}`}>
        <div className={styles.container}>
          <div className={`${styles.header} d-flex flex-row`}>
            <div className="col-3">
              <h1 className={styles.title}>Dashboard</h1>
            </div>
            <div className={`${styles.filters} col-9`}>
              {/* <select className={styles.select}>
                <option>Name</option>
              </select> */}

              {/* <select className={styles.select}>
                <option>Location</option>
              </select> */}
              <SelectComponent
                options={options}
                value={alcoholInfo.name}
                placeholder={"Item Description"}
                handleValueChange={handleValueChange}
                getSuggestionData={getSuggestionData}
                clearValueHandler={clearValueHandler}
                onFocusHandler={onFocusHandler}
                inputColumn={"itemName"}
                loadingSuggestion={loadingDescription}
                isMulti={false}
                isClearable={false}
              />
              <SelectComponent
                options={options2}
                placeholder={"Size"}
                value={alcoholInfo.size}
                isDisabled={alcoholInfo.name ? false : true}
                handleValueChange={handleValueChange}
                getSuggestionData={getSuggestionData}
                clearValueHandler={clearValueHandler}
                onFocusHandler={onFocusHandler}
                inputColumn={"size"}
                loadingSuggestion={loadingSuggestion}
                isMulti={false}
                isClearable={false}
              />
              <div>
                <ReactDatePicker
                  showIcon
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  disabled={false}
                  onChange={(update) => {
                    handleDateFilter(update);
                  }}
                  placeholderText="Date"
                  style={{ width: "20px" }}
                  // isClearable={true}
                  dateFormat={"dd/MM/yy"}
                />
              </div>
              <div
                style={{
                  position: "relative",
                }}
                onClick={handleResetFilters}
                className="statusContainer"
              >
                <span
                  className={`fw-500 pe-cursor price-button status view-offers`}
                >
                  Reset Filters
                </span>
              </div>
              {/* <select className={styles.select}>
                <option>Date</option>
              </select> */}
              {/* <div className={styles.searchContainer}>
                <Search size={16} />
                <input
                  type="text"
                  className={styles.searchInput}
                  placeholder="Search"
                />
              </div> */}
              <Notifications />
            </div>
          </div>
        </div>
      </div>
      <div className={`d-flex justify-content-between flex-column`}>
        {/* <div className={`${styles["column-1"]} ${styles["w-69"]}`}> */}
        <div className="row justify-content-between p-0 mb-3">
          <div className={`d-flex gap-3 justify-content-between col-8 p-0`}>
            {/* <div className={`custom-shadow ${styles.tableWrapper}`}>
              <h6 className="text-center">Most Inquired Alcohol</h6>
              <table className={`table table-borderless ${styles.table}`}>
                <thead>
                  <tr>
                    <th style={{ color: "black" }}>Description</th>
                    <th className={`${styles.last}`}>No of Query</th>
                  </tr>
                </thead>
                <tbody>
                  {!loadingInquiries && inquiries?.length > 0 ? (
                    inquiries
                      .filter((el) => el.totalInquiries > 0)
                      .map((el, index) => (
                        <tr key={index}>
                          <td>{el.itemName}</td>
                          <td className={`${styles.last}`}>
                            {el.totalInquiries}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr className="loading-container">
                      {loadingInquiries ? <DataLoader /> : "No Data"}
                    </tr>
                  )}
                </tbody>
              </table>
            </div> */}
            <div className={`custom-shadow ${styles.tableWrapper}`}>
              <h6 className={`text-center ${styles["title-cheapest-alc"]}`}>
                Cheapest Alcohols
              </h6>
              <table className={`table ${styles.table}`}>
                <thead>
                  <tr>
                    <th style={{ color: "black" }}>S. Code</th>
                    <th>Supplier Name</th>
                    <th>Cs Avail</th>
                    <th className={`${styles.last}`}>Offer Price(€)</th>
                    <th className={`${styles.last}`}>Offer Price($)</th>
                    <th className={`${styles.last}`}>Offer Price(£)</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {cheapestAlcohol?.length > 0 &&
                  cheapestAlcohol[0]?.pricePerBottleEuro ? (
                    cheapestAlcohol.map((el, index) => (
                      <tr key={index}>
                        <td
                          onClick={() =>
                            Navigate(`/supplier-informations/${el?.sCode}`, {
                              state: { supplierCode: el?.sCode },
                            })
                          }
                        >
                          <span
                            style={{
                              textDecoration: "underline",
                              color: "blue",
                            }}
                            className="pe-cursor"
                          >
                            <OverlayTrigger
                              placement="right"
                              overlay={tooltipGetSupplier}
                            >
                              <span>
                                {el.sCode}
                              </span>
                            </OverlayTrigger>
                          </span>
                        </td>
                        <td className="">{el.supplierName}</td>
                        <td
                          style={{
                            textDecoration: "underline",
                            color: "blue",
                          }}
                          className="pe-cursor"
                          onClick={() =>
                            Navigate("/offer-management", {
                              state: {
                                offer_id: el._id,
                                cameFrom: "Dashboard",
                              },
                            })
                          }
                        >
                          <OverlayTrigger
                            placement="right"
                            overlay={tooltipShowOffer}
                          >
                            <span>
                              {el.csAvail}
                            </span>
                          </OverlayTrigger>
                        </td>
                        <td className={`${styles.last}`}>
                          {getEuroValue(
                            el.pricePerBottleEuro,
                            el.currency,
                            conversionRate
                          )}
                        </td>
                        <td className={`${styles.last}`}>
                          {getDollarValue(
                            el.pricePerBottleEuro,
                            el.currency,
                            conversionRate
                          )}
                        </td>
                        <td className={`${styles.last}`}>
                          {getPoundValue(
                            el.pricePerBottleEuro,
                            el.currency,
                            conversionRate
                          )}
                        </td>
                        <td>
                          <span className=''>
                            {formatDate(el.date)}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="loading-container">
                      {loadingInquiries ? <DataLoader /> : "No Data"}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {/* <div
              style={{ height: "22.5rem" }}
              className={`custom-shadow ${styles.padding} ${
                !isAdmin ? styles["w-69"] : styles["w-100"]
              }`}
            >
              <div className={`${styles["stack-2-title"]}`}>
                <h4>Most Inquired Alcohol</h4>
              </div>
              {!loadingInquiries && inquiries?.length > 0 ? (
                <HorizontalBarChart inquiries={inquiries} />
              ) : (
                <div className="align-center">
                  {loadingInquiries ? <DataLoader /> : "No Data"}
                </div>
              )}
            </div> */}

            {/* <div
              className={`d-flex mt-4 gap-3 justify-content-between ${styles["w-69"]}`}
            >
             <div
            className={`custom-shadow ${styles.padding} ${styles.doughnut} ${styles["w-29"]}`}
          >
            <h4 className={`text-center ${styles["stack-2-title"]}`}>
              Resolved Inquiries
            </h4>
            {filteredInquiries?.resolvedInquiries ? (
              <DoughnutChart
                cutout={"65%"}
                bgColor={"#4339f2"}
                value={filteredInquiries.resolvedInquiries}
                fontSize={30}
                marginTop={"1rem"}
                subValue={"offers"}
              />
            ) : (
              <div className="text-center">No Data!</div>
            )}
          </div> 
            </div> */}

            {/* {!isAdmin && (
              <div
                className={`custom-shadow ${styles.padding} ${styles.doughnut} ${styles["w-29"]}`}
              >
                <h4 className={`text-center ${styles["stack-2-title"]}`}>
                  Received Offers
                </h4>
                {offersCount?.current && offersCount?.total ? (
                  <DoughnutChart
                    cutout={"65%"}
                    bgColor={"#34b53a"}
                    value={offersCount.current}
                    totalValue={offersCount.total}
                    fontSize={30}
                    subValue={"offers"}
                    mainLabel={alcoholInfo.size}
                    secondaryLabel={"Other"}
                  />
                ) : (
                  <div className="text-center">No Data!</div>
                )}
              </div>
            )} */}
          </div>

          <div
            style={{ width: "32%", height: "auto" }}
            className={`custom-shadow ${styles.padding} ${styles.doughnut}`}
          >
            <h4 className={`text-center ${styles["stack-2-title"]}`}>
              Received Offers
            </h4>
            {offersCount?.current && offersCount?.total ? (
              <DoughnutChart
                cutout={"65%"}
                bgColor={"#34b53a"}
                value={offersCount.current}
                totalValue={offersCount.total}
                fontSize={30}
                marginTop={"1rem"}
                subValue={"offers"}
                mainLabel={alcoholInfo.name}
                secondaryLabel={"Other"}
                // height={isAdmin ? "180px" : "86%"}
                height={"180px"}
              />
            ) : (
              <div className="text-center">No Data!</div>
            )}
          </div>
        </div>
        {/* <div className={`${styles["column-2"]} ${styles["w-29"]}`}> */}
        <div className="row justify-content-between flex-row">
          <div
            style={{ width: "32%", height: "auto" }}
            className={`custom-shadow ${styles.padding} `}
          >
            <div className={`mb-5 ${styles.barTitle}`}>
              <h4 className="m-0">Offers Stats</h4>
              <p>{alcoholInfo.name}</p>
            </div>
            {statsData?.length > 0 ? (
              <BarChart statsData={statsData} />
            ) : (
              <div className="text-center">No Data!</div>
            )}
          </div>

          {isAdmin && (
            <div
              style={{ width: "32%", height: "auto", height: "auto" }}
              className={`custom-shadow ${styles.padding} ${styles.doughnut}`}
            >
              <h4 className={`text-center ${styles["stack-2-title"]}`}>
                Inquiry Status
              </h4>
              {filteredInquiries?.pendingInquiries ||
              filteredInquiries.resolvedInquiries ? (
                // <DoughnutChart
                //   cutout={"65%"}
                //   bgColor={"#ffb200"}
                //   value={filteredInquiries.pendingInquiries}
                //   fontSize={30}
                //   marginTop={"1rem"}
                //   subValue={"inquiries"}
                // />
                <div style={{ height: "180px" }} className="mt-3 align-center">
                  <PieChart
                    pending={filteredInquiries.pendingInquiries}
                    resolved={filteredInquiries.resolvedInquiries}
                  />
                </div>
              ) : (
                <div className="text-center">No Data!</div>
              )}
            </div>
          )}
          <div
            style={{ width: "32%", height: "auto" }}
            className={`custom-shadow ${styles.padding} ${styles.doughnut}`}
          >
            <h4 className={`text-center mb-3 ${styles["stack-2-title"]}`}>
              Alcohols
            </h4>
            {cheapestAlcohol?.length > 0 &&
            cheapestAlcohol[0]?.pricePerBottleEuro ? (
              <>
                <DoughnutChartPrice
                  cutout={"80%"}
                  bgColor={"#4339f2"}
                  // fontSize={isAdmin ? 20 : 50}
                  fontSize={20}
                  value={
                    cheapestAlcohol?.length > 0
                      ? getFixedNumber(cheapestAlcohol[0]?.pricePerBottleEuro)
                      : 0
                  }
                  isCurrency={true}
                  minCurrency={
                    cheapestAlcohol?.length > 0
                      ? cheapestAlcohol[0].currency
                      : "EUR"
                  }
                  maxCurrency={
                    mostExpensiveAlcohol?.length > 0
                      ? mostExpensiveAlcohol[0].currency
                      : "EUR"
                  }
                  totalValue={totalValue}
                  marginTop={"3rem"}
                  mainLabel={"Cheapest"}
                  secondaryLabel={"Most Expensive"}
                  // height={isAdmin ? "140px" : "70%"}
                  height={"140px"}
                />
                <div className={`text-center ${styles["stack-2-title"]}`}>
                  <h4 className="m-0">{alcoholInfo.name}</h4>
                  <p>{alcoholInfo.size}</p>
                </div>
              </>
            ) : (
              <div className="align-center">No Data!</div>
            )}
          </div>

          {!isAdmin && <div style={{ width: "32%", height: "auto" }}></div>}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
