import React, { useEffect, useState } from "react";
import addNotification from "react-push-notification";
import { useDispatch } from "react-redux";
import {
  setNotifications,
  setUnreadNotifications,
} from "../../_store/reducers/notificationReducer";
import { getAllNotifications, getNotifCount } from "../../requests";
import { notifyError } from "../helper/Toaster";
import { TOKEN_ERRORS } from "../../_data/codes";
import handleSessionClear from "../helper/handleSessionClear";
import { useNavigate } from "react-router-dom";

const PushNotifications = () => {
  const [newNotification, setNewNotification] = useState(false);
  const [oldNotif, setOldNotification] = useState(null);
  const [data, setData] = useState({});

  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const access_token = localStorage.getItem("access_token_MN");
  const isAdmin =
    JSON.parse(localStorage.getItem("user_info_MN"))?.status === 2;
  const isSales =
    JSON.parse(localStorage.getItem("user_info_MN"))?.status === 3;

  const isApprovedUser = isAdmin || isSales;

  var intervalId = null;

  useEffect(() => {
    if (access_token && isApprovedUser) {
      getNotifications();
    } else {
      Navigate("/login");
    }
  }, [isApprovedUser]);

  useEffect(() => {
    if (newNotification) {
      addNotification({
        title: data?.name,
        message: data?.message,
        theme: "darkblue",
        native: true, // when using native, your OS will handle theming.
        duration: 5000,
        onClick: () => {
          window.focus();
          console.log("url: ", `${process.env.PUBLIC_URL}/notifications`);
          window.location.href = `${process.env.PUBLIC_URL}/notifications`;
          // Dispatch a custom event
          // window.dispatchEvent(
          //   new CustomEvent("focusWindow", { detail: "/notifications" })
          // );
        },
      });
      // setNewNotification(false);
    }
  }, [newNotification]);

  useEffect(() => {
    if (access_token, isApprovedUser) {
      const intervalId = setInterval(() => {
        getNotificationsCount();
        // setNewNotification(true)
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [access_token, isApprovedUser]);

  // useEffect(() => {
  //   // Set up a listener for custom events
  //   window.addEventListener("focusWindow", handleFocusWindow);
  //   return () => {
  //     window.removeEventListener("focusWindow", handleFocusWindow);
  //   };
  // }, []);

  // const handleFocusWindow = (event) => {
  //   console.log("url: ", `${process.env.PUBLIC_URL}/${event.detail}`)
  //   window.open(`${process.env.PUBLIC_URL}/${event.detail}`)
  // };

  const getNotificationsCount = async () => {
    try {
      const response = await getNotifCount();
      if (response.status !== 200) {
        // setAuthFalse();
      }
      const receivedNotifications = response.data.response.count;
      const lastNotification = response.data.response.lastNotification;
      const unreadCount = response.data.response.unreadCount;

      const payload = {
        unreadCount,
      };

      dispatch(setUnreadNotifications(payload));

      setData(lastNotification[0]);

      let isFirstRun = true;
      setOldNotification((prevOldNotif) => {
        let isNewNotification = receivedNotifications > prevOldNotif;
        if (prevOldNotif === null) isNewNotification = false;
        setNewNotification(isNewNotification);

        if (isFirstRun && isNewNotification) {
          getNotifications();
        }
        isFirstRun = false;
        return receivedNotifications; // Update the oldNotif state with the current count
      });
      return receivedNotifications;
    } catch (error) {
      notifyError(error.response.data.message);
      if (TOKEN_ERRORS.includes(error?.response?.status)) {
        handleSessionClear();
        clearInterval(intervalId);
        Navigate("/login");
      }
    }
  };

  // COUNT NOTIFICATIONS
  const countUnreadNotifications = (notifications) => {
    let count = 0;
    for (let notification of notifications) {
      const reciepents = notification.receipent;
      for (let recepent of reciepents) {
        if (recepent.status === 0) {
          count += 1;
        }
      }
    }
    return count;
  };

  // GET SUGGESTION DATA FOR FILTER
  const getNotifications = async () => {
    try {
      const payload = {
        quickView: 1,
      };

      const response = await getAllNotifications(payload);

      if (response.status === 200) {
        const receivedNotifications = response.data.notifications;
        const unreadNotifications = countUnreadNotifications(
          receivedNotifications
        );

        const payload = {
          notification: receivedNotifications,
          unreadNotification: unreadNotifications,
        };

        dispatch(setNotifications(payload));
        // setAuthFalse();
      }
    } catch (error) {
      notifyError(error.response.data.message);
      if (TOKEN_ERRORS.includes(error?.response?.status)) {
        handleSessionClear();
        Navigate("/");
      }
    }
  };

  return <></>;
};

export default PushNotifications;
