import axios from "axios";
// require('dotenv').config();

const API_URL = process.env.REACT_APP_API_URL;

const Axios = axios.create({
  baseURL: API_URL, // Replace with your backend API URL
});

// Function to set the token in the request headers
const setAuthToken = () => {
  // const token = sessionStorage.getItem("aad_token");
  let token = localStorage.getItem("access_token_MN");
  token = token ? JSON.parse(token) : null;
  if (token) {
    // Apply the token to every request header
    Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return true;
    // Axios.defaults.headers.common["tid"] = tid;
  } else {
    // Remove the token from the request headers
    delete Axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("access_token_MN")
    localStorage.removeItem("user_info_MN")
    return false;
    // sessionStorage.removeItem("aad_token"); // Remove the token from local storage
    // sessionStorage.removeItem("tid");
  }
};

export { Axios, setAuthToken };
