// src/HorizontalBarChart.js
import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import styles from "../../../_css/Chart.module.css";

// Register the plugin
Chart.register(ChartDataLabels);

const HorizontalBarChart = ({inquiries}) => {
  const data = {
    labels: inquiries.map(el => el.name),
    datasets: [
      {
        label: "Inquiries (%)",
        data: inquiries.map(el => el.percentage).sort((a, b) => b - a),
        backgroundColor: [
          "#4339f2",
          "#c6d1dd96",
          "#c6d1dd96",
          "#c6d1dd96",
          "#c6d1dd96",
          "#c6d1dd96",
          "#c6d1dd96",
        ],
        // hoverBackgroundColor: [
        //   '#434190',
        //   '#E2E8F0',
        //   '#E2E8F0',
        //   '#E2E8F0',
        //   '#E2E8F0',
        // ],
        borderRadius: 4,
        categoryPercentage: 0.7, // Add space between bars (0 to 1)
      },
    ],
  };

  const options = {
    indexAxis: "y",
    responsive: true,
    barThickness: 30,
    maintainAspectRatio: false,

    layout: {
      padding: {
        left: 0, // Adjust padding values as needed
        right: 0,
        top: 10,
        bottom: 40,
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "black",
        anchor: "end",
        align: "start",
        formatter: (value) => `${value}%`,
        font: {
          weight: "bold",
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false, // Hide X-axis grid lines (includes line)
        },
        ticks: {
          display: false, // Hide X-axis ticks and labels (optional)
        },
        display: false, // Hide the entire X-axis (line and labels)
      },
      y: {
        grid: {
          display: false, // Hide Y-axis grid lines (includes line)
        },
        ticks: {
          display: false, // Hide Y-axis ticks and labels (optional)
        },
        display: false, // Hide the entire Y-axis (line and labels)
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default HorizontalBarChart;
