import React, { useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import classes from "../_css/Login.module.css";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/authConfig";
import { useDispatch } from "react-redux";
import { setUserInformation } from "../_store/reducers/userReducer";
import { callMsGraph } from "../config/graph";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { startLoader, stopLoader } from "../_store/reducers/loaderReducer";
import { userLogin } from "../requests";

const MicrosoftButton = () => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  useEffect(() => {
    const initializeLogin = async () => {
      try {
        await instance.initialize();
        const redirectResponse = await instance.handleRedirectPromise();
        if (redirectResponse) {
          instance.setActiveAccount(redirectResponse.account);
        }
      } catch (error) {
        console.error("Redirect Error:", error);
      }
    };

    initializeLogin();
  }, [instance]);

  const handleLogin = async () => {
    try {
      const loginResponse = await instance.loginPopup(loginRequest);

      if (loginResponse) {
        dispatch(startLoader());
        instance.setActiveAccount(loginResponse.account);

        // GET TOKEN AND TID TO SENT TO BACKEND LATER
        // const token = loginResponse.idToken;
        // const tid = loginResponse.idTokenClaims.tid;

        await RequestProfileData();
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(stopLoader());
    }
  };

  const RequestProfileData = async () => {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    let response = {};
    try {
      const accessTokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      
      const accessToken = accessTokenResponse.accessToken;
      response = await callMsGraph(accessToken);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }

    try {
      const { displayName, mail, userPrincipalName } = response;
      let email = mail;
      if (!mail || _.isEmpty(mail)) {
        email = userPrincipalName;
      }
      dispatch(setUserInformation(displayName, "", email, 0));

      const payload = {
        email: email,
        name: displayName,
      };
      const apiResponse = await userLogin(payload);
      if (apiResponse && apiResponse.status === 200) {
        const { id, status, name, email } = apiResponse.data.userData;

        localStorage.setItem(
          "access_token_MN",
          JSON.stringify(apiResponse.data.accessToken)
        );
        localStorage.setItem("user_info_MN", JSON.stringify(apiResponse.data.userData));
        dispatch(setUserInformation(name, id, email, status));
        setTimeout(Navigate("/"), 5000);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  return (
    <Button
      style={{
        background: "#4339f2",
      }}
      className={`d-flex align-items-center btn-microsoft text-white w-100 justify-content-center`}
      onClick={handleLogin}
    >
      <i className={`${classes["icon-microsoft"]} mr-2`}>
        <img
          src={`${process.env.PUBLIC_URL}/img/microsoft_PNG5.png`}
          alt="Microsoft Logo"
          width="24"
          height="24"
          className=""
        />
      </i>
      Sign in with Microsoft
    </Button>
  );
};

export default MicrosoftButton;
