import React from "react";
import { FallingLines } from "react-loader-spinner";

function DataLoader() {
  return (
    <div className="data-loader">
      <FallingLines
        color="#4339f2"
        width="100"
        visible={true}
        className="data-loader-falling-lines"
        ariaLabel="falling-circles-loading"
      />
    </div>
  );
}

export default DataLoader;
