import AdminPanel from "../_components/AdminPanle";
import OfferManagement from "../_components/OfferManagement";
import OfferManagementTwo from "../_components/OfferManagementTwo";
import ProductCatalogue from "../_components/ProductCatalogue";
import Dashboard from "../_pages/Dashboard";
import Home from "../_pages/Home";
import Notifications from "../_pages/Notifications";
import SupplierDetails from "../_pages/SupplierDetails";
import SupplierInformation from "../_pages/SupplierInformation";

const ProtectedRotues = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/admin-panel",
    element: <AdminPanel />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/product-catalogue",
    element: <ProductCatalogue />,
  },
  {
    path: "/offer-management",
    element: <OfferManagement />,
  },
  {
    path: "/offer-management/:productId",
    element: <OfferManagementTwo />,
  },
  {
    path: "/notifications",
    element: <Notifications />,
  },
  {
    path: "/supplier-informations",
    element: <SupplierInformation />,
  },
  {
    path: "/supplier-informations/:supplierCode",
    element: <SupplierDetails />,
  },
  // {
  //   path: "/bots/:botId",
  //   element: <ChatPage />
  // }
];

export default ProtectedRotues;
