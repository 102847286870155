const IS_LOADING = "IS_LOADING";
const STOP_LOADING = "STOP_LOADING";

// Define reducer function
const loaderReducer = (state = { isLoading: false }, action) => {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const startLoader = () => ({
  type: IS_LOADING,
});

const stopLoader = () => ({
  type: STOP_LOADING,
});

export { startLoader, stopLoader };
export default loaderReducer;
