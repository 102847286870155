import React, { useEffect, useState } from "react";
import Pending from "../_components/UI/Pending";
import SuperAdmin from "../_components/UI/SuperAdmin";
import Blocked from "../_components/UI/Blocked";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startLoader, stopLoader } from "../_store/reducers/loaderReducer";
import { getUserInfo } from "../requests";
import { notifyError } from "../_components/helper/Toaster";
import { TOKEN_ERRORS } from "../_data/codes";
import handleSessionClear from "../_components/helper/handleSessionClear";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let user = JSON.parse(localStorage.getItem("user_info_MN"));
  const access_token = localStorage.getItem("access_token_MN");

  const [status, setStatus] = useState(user?.status || 0);

  useEffect(() => {
    const fetchUserInfo = async () => {
      dispatch(startLoader());

      try {
        const response = await getUserInfo();
        if (response && response.status === 200) {
          localStorage.setItem(
            "user_info_MN",
            JSON.stringify(response.data.userData)
          );
        }

        setStatus(response.data.userData?.status || 0);

        if ([2, 3].includes(user.status)) {
          navigate("/dashboard");
        }
      } catch (err) {
        console.log("err: ", err);
        notifyError(err.response.data.message);
        if (TOKEN_ERRORS.includes(err.response.status)) {
          handleSessionClear();
          navigate("/login");
        }
      } finally {
        dispatch(stopLoader());
      }
    };
    if (access_token) {
      fetchUserInfo();
    } else {
      navigate("/login");
    }
  }, [access_token]); // Empty dependency array to run only once on mount

  return (
    <div>
      {status === 0 && <Pending />}
      {status === 1 && <SuperAdmin />}
      {status === 9 && <Blocked />}
    </div>
  );
}

export default Home;
