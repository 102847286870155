// Define initial state
const initialState = {
    previousPage: "",
    currentPage: "",
    cameFrom: ""
  };
  
  // Define action types
  const SET_PAGE = "SET_PAGE";
  const CAME_FROM = "CAME_FROM";
  
  // Define reducer function
  const pageReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_PAGE:
        return {
          ...state,
          previousPage: action.payload.previousPage,
          currentPage: action.payload.currentPage,
        };
      case CAME_FROM:
        return {
          ...state,
          cameFrom: action.payload.cameFrom,
        };
      default:
        return state;
    }
  };
  
  // Action creators
  const setPage = (payload) => ({
    type: SET_PAGE,
    payload: payload,
  });

  const cameFrom = (payload) => ({
    type: CAME_FROM,
    payload: payload,
  });
  
  
  export { setPage, cameFrom };
  export default pageReducer;
  