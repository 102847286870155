// src/components/ProductDetails.js
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import styles from "../../_css/ProductDetails.module.css";
import NotesModal from "./NotesModal";
import { formatDate } from "../helper/formatters";
import _ from "lodash";

const ProductDetails = ({ notes, handleNotesUpdate, quantity }) => {
  const [showModal, setShowModal] = useState(false);
  const [note, setNote] = useState(null);

  const handleShowModal = (note) => {
    setNote(note);
    setShowModal(!showModal);
  };

  const handleContinue = (notes, action) => {
    setShowModal(!showModal);
    handleNotesUpdate(notes, action);
  };

  return (
    <div className={styles.tableContainer}>
      <Table bordered className={styles.customTable}>
        <tbody>
          <tr>
            <th>Date Inquired</th>
            <td>{formatDate(notes[0].createdAt)}</td>
          </tr>
          <tr>
            <th>Qty Requested in Cases</th>
            <td>{quantity || 0}</td>
          </tr>
          <tr>
            <th>Actions</th>
            <td>{notes[notes.length - 1]?.actions || "Inquiry from Sales"}</td>
          </tr>
          <tr>
            <th>Last Action Performed Date</th>
            <td>{formatDate(notes[0].updatedAt)}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>
              <div className={styles.statusContainer}>
                {notes.map((note, index) => {
                  if (_.toLower(note.type) === "add") return;
                  return (
                    <span
                      key={index}
                      className={`${styles.status} ${
                        _.toLower(note.type) === "purchase"
                          ? styles.statusPurchased
                          : _.toLower(note.type) === "reject"
                          ? styles.statusRejected
                          : styles.statusPending
                      }`}
                    >
                      {_.toLower(note.type) === "purchase"
                        ? "Purchased"
                        : _.toLower(note.type) === "reject"
                        ? "Rejected"
                        : "Pending"}
                    </span>
                  );
                })}
                {/* <span className={styles.statusPending}>Pending</span>
                <span className={styles.statusPurchased}>Purchased</span> */}
              </div>
            </td>
          </tr>
          <tr>
            <th>Notes</th>
            <td className="p-0">
              <Table className="mb-0" borderless>
                <tbody>
                  {notes.map((value, index) => (
                    <tr key={index} id={`status_${index}`}>
                      <td>
                        {value.name}{" "}
                        <span
                          className={`badge ${
                            value.role === "Sales"
                              ? "m-color-green-bg"
                              : value.role === "Deleted"
                              ? "m-color-yellow-bg"
                              : "m-color-blue-bg"
                          }`}
                        >
                          {value.role}
                        </span>
                      </td>
                      <td className={styles.notesContainer}>
                        {value.message}
                        {/* {value.isOwner && (
                          <div className="d-flex" style={{ gap: "8px" }}>
                            <FilePenLine
                              onClick={() => handleShowModal(value)}
                              size={16}
                              className={styles.icon}
                            />

                            <Trash2
                              onClick={() =>
                                handleNotesUpdate("", value._id, true, true)
                              }
                              size={16}
                              className={styles.icon}
                            />
                          </div>
                        )} */}
                      </td>
                      <td>{formatDate(value.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </td>
          </tr>
        </tbody>
      </Table>
      <NotesModal
        showModal={showModal}
        selectedAction={note?._id}
        handleShowModal={handleShowModal}
        handleContinue={handleContinue}
        title={"Edit Note"}
        note={note?.message}
      />
    </div>
  );
};

export default ProductDetails;
