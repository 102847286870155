import { Navigate, Outlet } from "react-router-dom";
import Navbar from "../_components/common/Navbar";
import Sitemap from "../_components/common/Sitemap";

const ProtectedRoutesOutlet = () => {
  const auth = { token: localStorage.getItem('access_token_MN') };
  // const url = window.location.href;
  // const {urlParam} = useParams();
  // const location = useLocation()
  // const auth = { token: "Authenticated" };
  return auth.token ? (
    <>
      {/* Header | Footer | Drawer for Protected Routes add here */}
      <header className="App-header">
        <Sitemap />
        <Navbar />
      </header>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoutesOutlet;
