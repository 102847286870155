import React, { useEffect, useState } from "react";
import styles from "../_css/Notifications.module.css";
import SelectComponent from "../_components/common/SelectComponent";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startLoader, stopLoader } from "../_store/reducers/loaderReducer";
import { notifyError, notifySuccess } from "../_components/helper/Toaster";
import QuickNotif from "../_components/common/Notifications";
import { formatDate } from "../_components/helper/formatters";
import { ChevronDown, ChevronUp, Dot, Trash2 } from "lucide-react";
import Paginate from "../_components/UI/Pagination";
import ReactDatePicker from "react-datepicker";
import { getNotifs, getNotifsSearch, notifsUpdate } from "../requests";
import { TOKEN_ERRORS } from "../_data/codes";
import handleSessionClear from "../_components/helper/handleSessionClear";

const STATUS_LIST = ["new", "read", "archive", "all"];

const Notifications = () => {
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    totalPages: 2,
    totalPosts: 10,
  });
  const [sortedColumn, setSortedColumn] = useState({
    column: String,
    sortDsc: true,
  });

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [itemToBeDeleted, setItemToBeDeleted] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [sortingData, setSortingData] = useState(null);
  const [loadingSuggestion, setLoadingSuggestion] = useState(false);
  const [perPage, setPerPage] = useState(5);

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const access_token = localStorage.getItem("access_token_MN");

  const handleSort = (key) => {
    let lowerKey = _.toLower(key);

    if (lowerKey === "notification") lowerKey = "message";
    if (lowerKey === "date") lowerKey = "createdAt";

    let previousLowerKey = !_.isEmpty(sortedColumn.column)
      ? _.toLower(sortedColumn.column)
      : null;

    if (previousLowerKey === "notification") previousLowerKey = "message";
    if (previousLowerKey === "date") previousLowerKey = "createdAt";

    let sortedData = data;
    let coloumnChanged = false;

    if (previousLowerKey && lowerKey !== previousLowerKey) {
      coloumnChanged = true;
      sortedData = [...data].sort((a, b) => {
        if (a[previousLowerKey] < b[previousLowerKey])
          return sortedColumn.sortDsc ? -1 : 1;
        if (a[previousLowerKey] > b[previousLowerKey])
          return sortedColumn.sortDsc ? 1 : -1;
        return 0;
      });
    }

    const sortingBasis = coloumnChanged ? coloumnChanged : sortedColumn.sortDsc;
    sortedData = [...sortedData].sort((a, b) => {
      if (a[lowerKey] < b[lowerKey]) return sortingBasis ? -1 : 1;
      if (a[lowerKey] > b[lowerKey]) return sortingBasis ? 1 : -1;
      return 0;
    });

    setSortedColumn({ column: key, sortDsc: !sortingBasis });
    setData(sortedData);
  };

  useEffect(() => {
    if (access_token) {
      getNotifications();
    } else {
      Navigate("/login");
    }
  }, [page, filterData, perPage, sortingData]);

  // COUNT NOTIFICATIONS
  const countUnreadNotifications = (notifications) => {
    let count = 0;
    for (let notification of notifications) {
      const reciepents = notification.receipent;
      for (let recepent of reciepents) {
        if (recepent.status === 0) {
          count += 1;
        }
      }
    }
    return count;
  };

  // GET SUGGESTION DATA FOR FILTER
  const getNotifications = async () => {
    dispatch(startLoader());

    let isSorting = false;

    // CHECK IF SORTING IS APPLIED
    if (sortingData) {
      isSorting = true;
    }

    try {
      const payload = {
        page,
        limit: perPage || 5,
        isSorting,
        sortingData,
        filterData,
      };
      const response = await getNotifs(payload);
      if (response.status === 200) {
        const { notifications, suggestionDataNames } = response.data;
        setData(notifications);

        // for pagination
        const pageInfo = {
          totalPages: response.data.totalPages || 1,
          totalNotifications: response.data.totalNotifications || 0,
          totalPosts: response.data.totalNotifications || 0,
        };
        setPageInfo(pageInfo);
        setOptions(suggestionDataNames);
        // setAuthFalse();
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleValueChange = async (value, column) => {
    if (value && value?.length > 0 && column) {
      let values = [];
      for (const data of value) {
        values.push(data.value);
      }
      const reqData = {
        value: values,
        column: column,
      };

      setSortingData(reqData);
      // await getNotifications(true, reqData);
    } else {
      setSortingData(null);
    }
    handleResetPage();
  };

  const handleDateFilter = async (selectedDateRange) => {
    setDateRange(selectedDateRange);

    const [startDate, endDate] = selectedDateRange;

    if (startDate && endDate) {
      setSortingData({ column: "createdAt", value: selectedDateRange });
    } else {
      setSortingData(null);
    }
    handleResetPage();
  };

  const clearValueHandler = () => {
    handleResetPage();
    setOptions([]);
    setSortingData(null);
    setDateRange([null, null]);
  };

  const handleNext = async (page) => {
    if (page + 1 <= pageInfo.totalPages) {
      setPage(page + 1);
      // setData(initialData.slice(5, 10));
    }
  };

  const handlePrevious = async (page) => {
    if (page - 1 > 0) {
      setPage(page - 1);
      // setData(initialData.slice(0, 5));
    }
  };

  const handleResetPage = () => {
    setPage(1);
  };

  const handlePerPage = (count) => {
    setPerPage(count);
  };

  // GET SUGGESTION DATA FOR FILTER
  const getSuggestionData = async (inputColumn, inputValue) => {
    if (_.isEmpty(inputColumn) || _.isEmpty(inputValue)) return;

    setLoadingSuggestion(true);
    try {
      const payload = {
        searchFor: inputColumn,
        searchString: inputValue,
      };
      const response = await getNotifsSearch(payload);
      if (response.status === 200) {
        const { formattedData } = response.data;

        setOptions(formattedData);
        // setAuthFalse();
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      setLoadingSuggestion(false);
    }
  };

  const statusClickHandler = async (status) => {
    const element = document.getElementById(`status-${status}`);

    if (element) {
      element.classList.add("selected-status");

      for (let statusName of STATUS_LIST) {
        if (statusName === status) {
          continue;
        }
        const oldElement = document.getElementById(`status-${statusName}`);
        if (oldElement) oldElement.classList.remove("selected-status");
      }
    }

    setFilterData(status);
    // await getNotifications(false, {});
  };

  // GET SUGGESTION DATA FOR FILTER
  const updateNotification = async (
    notifId,
    isDelete = false,
    isRead = false
  ) => {
    if (isDelete) {
      if (itemToBeDeleted.length < 1) return;
    }

    dispatch(startLoader());

    try {
      const payload = {
        notifId,
        isDelete,
        isRead,
      };
      const response = await notifsUpdate(payload);
      if (response.status === 200 && isDelete) {
        notifySuccess(response.data.message);
        await getNotifications();
        setItemToBeDeleted([]);
        // setAuthFalse();
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  const notificationClickHandler = async (notif) => {
    const id = notif.productId;
    const notifId = notif._id;

    await updateNotification(notifId, false, true);

    Navigate(`/offer-management/${id}`, {
      state: { element_id: id, lastPage: "Notifications" },
    });
  };

  const checkboxClickHandler = (e, notif) => {
    let newItemToBeDeleted = itemToBeDeleted || [];
    let checkRemove = false;

    newItemToBeDeleted = newItemToBeDeleted.filter((item) => {
      if (item === notif._id) {
        checkRemove = true;
      }
      return item !== notif._id;
    });
    // }

    if (!checkRemove) {
      newItemToBeDeleted.push(notif._id);
    }

    setItemToBeDeleted(newItemToBeDeleted);
  };

  return (
    <div
      className={`container ml-5rem mt-4 ${styles["notifications-container"]}`}
    >
      <div className={`position-relative mb-4 ${styles.filterSection}`}>
        <div className={styles.container}>
          <div className={`${styles.header} d-flex flex-row`}>
            <div className="col-3">
              <h1 className={styles.title}>Notifications</h1>
            </div>
            <div className={`${styles.filters} col-9`}>
              {/* <select className={styles.select}>
                <option>Name</option>
              </select> */}
              <div>
                <ReactDatePicker
                  showIcon
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    handleDateFilter(update);
                  }}
                  placeholderText="Date"
                  style={{ width: "20px" }}
                  isClearable={true}
                  dateFormat={"dd/MM/yy"}
                />
              </div>
              <SelectComponent
                options={options}
                placeholder={"Name"}
                handleValueChange={handleValueChange}
                getSuggestionData={getSuggestionData}
                clearValueHandler={clearValueHandler}
                onFocusHandler={(e) => {}}
                inputColumn={"name"}
                loadingSuggestion={loadingSuggestion}
              />

              {/* <select className={styles.select}>
                <option>Location</option>
              </select> */}
              {/* <SelectComponent
                options={options}
                placeholder={"Category"}
                handleValueChange={handleValueChange}
                getSuggestionData={getSuggestionData}
                clearValueHandler={clearValueHandler}
                inputColumn={"category"}
              /> */}
              {/* <select className={styles.select}>
                <option>Date</option>
              </select> */}
              {/* <div className={styles.searchContainer}>
                <Search size={16} />
                <input
                  type="text"
                  className={styles.searchInput}
                  placeholder="Search"
                />
              </div> */}
              <QuickNotif />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`container mt-5 rounded position-relative p-1 ${styles.table}`}
      >
        <div className="d-flex gap-5 bg-white height-4r align-items-center">
          {/* <div>
            <OverlayTrigger placement="right" overlay={tooltipDelete}>
              <button
                onClick={() => updateNotification(itemToBeDeleted, true, false)}
                className="btn"
              >
                <i className="bi bi-trash">
                  <Trash2 size={16} />
                </i>
              </button>
            </OverlayTrigger>
          </div> */}
          <div className={`d-flex align-items-center ${styles.statusBlock}`}>
            <div
              onClick={() => statusClickHandler("all")}
              className={`pe-cursor selected-status ${styles.statusAll}`}
              id="status-all"
            >
              <span>All</span>
            </div>
            <div
              onClick={() => statusClickHandler("new")}
              className={`pe-cursor ${styles.statusNew}`}
              id="status-new"
            >
              <i className={styles.dotNew}>
                <Dot size={72} />
              </i>
              <span>New</span>
            </div>
            {/* <div
              onClick={() => statusClickHandler("unread")}
              className={`pe-cursor ${styles.statusUnread}`}
              id="status-unread"
            >
              <i className={styles.dotUnread}>
                <Dot size={72} />
              </i>
              <span>Unread</span>
            </div> */}
            <div
              onClick={() => statusClickHandler("read")}
              className={`pe-cursor ${styles.statusArchieve}`}
              id="status-read"
            >
              <i className={styles.dotArchive}>
                <Dot size={72} />
              </i>
              <span>Read</span>
            </div>
          </div>
        </div>
        <div style={{maxHeight: "330px"}} className="table-height-400">
          <table className={`table custom-table ${styles["border-top"]}`}>
            <thead>
              <tr>
                {/* <th style={{ width: "7%" }} scope="col">
                <input type="checkbox" />
              </th> */}
                <th
                  className="pe-cursor"
                  onClick={() => handleSort("Date")}
                  style={{ width: "20%" }}
                  scope="col"
                >
                  <span>Date</span>
                  <i>
                    {sortedColumn.column === "Date" ? (
                      sortedColumn.sortDsc ? (
                        <ChevronUp size={16} />
                      ) : (
                        <ChevronDown size={16} />
                      )
                    ) : (
                      <ChevronUp size={16} />
                    )}
                  </i>
                </th>
                <th
                  // style={{
                  //   minWidth: widthColoumns.includes(e) ? "98px" : "80px",
                  // }}
                  style={{ width: "20%" }}
                  // onClick={() => handleSort("Name")}
                >
                  <div className={`${styles["col-value"]}`}>
                    <span>Name</span>
                    {/* <i>
                    {sortedColumn.column === "Name" ? (
                      sortedColumn.sortDsc ? (
                        <ChevronUp size={16} />
                      ) : (
                        <ChevronDown size={16} />
                      )
                    ) : (
                      <ChevronUp size={16} />
                    )}
                  </i> */}
                  </div>
                </th>
                <th
                  // className="pe-cursor"
                  // style={{
                  //   minWidth: widthColoumns.includes(e) ? "98px" : "80px",
                  // }}
                  style={{ width: "55%" }}
                  // onClick={() => handleSort("Notification")}
                >
                  <div className={`${styles["col-value"]}`}>
                    <span>Notification</span>
                    {/* <i>
                    {sortedColumn.column === "Notification" ? (
                      sortedColumn.sortDsc ? (
                        <ChevronUp size={16} />
                      ) : (
                        <ChevronDown size={16} />
                      )
                    ) : (
                      <ChevronUp size={16} />
                    )}
                  </i> */}
                  </div>
                </th>
                <th style={{ width: "5%" }} scope="col">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((notif, index) => (
                <tr key={index}>
                  <td>{formatDate(notif.createdAt, true)}</td>
                  <td>
                    {/* {" "}
                  <input
                    onClick={(e) => checkboxClickHandler(e, notif)}
                    className={styles.checkbox}
                    type="checkbox"
                    checked={itemToBeDeleted.includes(notif._id)}
                  />{" "} */}
                    {notif.name}
                  </td>
                  <td
                    className="pe-cursor"
                    onClick={() => notificationClickHandler(notif)}
                  >
                    {notif.message}
                  </td>
                  <td>
                    {notif.receipent.length > 0 &&
                      notif.receipent[0]?.status === 1 && (
                        <i className={styles.dotArchive}>
                          <Dot size={72} />
                        </i>
                      )}
                    {notif.receipent.length > 0 &&
                      notif.receipent[0]?.status === 0 && (
                        <i className={styles.dotNew}>
                          <Dot size={72} />
                        </i>
                      )}
                    {/* {notif.receipent.length > 0 &&
                    notif.receipent[0]?.status === 1 &&
                    !checkNewNotif(notif.createdAt) && (
                      <i className={styles.dotArchive}>
                        <Dot size={72} />
                      </i>
                    )} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ bottom: "-70px" }} className="position-absolute w-100">
          <Paginate
            currentPage={page}
            pageInfo={pageInfo}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handlePerPage={handlePerPage}
            resetPage={handleResetPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Notifications;
