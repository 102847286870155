import React, { useRef, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { formatDate } from "../../helper/formatters";

const BarChart = ({ statsData }) => {
  const chartRef = useRef(null);
  const [gradient, setGradient] = useState(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
      gradient.addColorStop(0, "rgba(67, 57, 242, 1)");
      gradient.addColorStop(0.97, "rgba(255, 255, 255, 1)");
      setGradient(gradient);
    }
  }, [chartRef]);

  const data = {
    labels: [...statsData].reverse().map((el) => formatDate(el.date)),
    datasets: [
      {
        label: "Offers Received",
        data: [...statsData].reverse().map((el) => el.count),
        backgroundColor: gradient, // Use the gradient for the bar color
        borderColor: "rgba(67, 57, 242, 1)",
        borderWidth: 0,
        borderRadius: 6, // Add border-radius to bars
      },
    ],
  };

  const options = {
    indexAxis: "x", // For horizontal bar chart
    barThickness: 30, // Fixed bar width in pixels
    categoryPercentage: 0.8, // Adjust category width
    barPercentage: 0.8, // Adjust bar width within category
    plugins: {
      legend: {
        display: false, // Remove the legend
      },
      // Disable datalabels plugin
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove vertical grid lines
        },
      },
      y: {
        grid: {
          drawBorder: false, // Remove the y-axis border line
        },
        ticks: {
          // callback: (value, index, values) => {
          //   return `€${value}`; // Prepend euro sign before value
          // },
          stepSize: 1, // Ensures y-axis ticks are whole numbers
        },
      },
    },
  };

  return <Bar ref={chartRef} data={data} options={options} />;
};

export default BarChart;
