import React from "react";
import { Pie } from "react-chartjs-2";

const PieChart = ({ pending, resolved }) => {
  const data = {
    labels: ["Pending Inquiries", "Resolved Inquiries"],
    datasets: [
      {
        label: "Inquiries",
        data: [pending, resolved], // Example data: 12 pending, 8 resolved
        backgroundColor: ["#ffb200", "#4339f2"],
        hoverBackgroundColor: ["#ffb200", "#4339f2"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Remove the legend
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataLabel = tooltipItem.label;
            const value = tooltipItem.raw;
            return `${dataLabel}: ${value}`;
          },
        },
      },
      datalabels: {
        color: "#fff", // Font color
        font: {
          size: 16, // Font size
          weight: "bold", // Font weight
        },
      },
    },
  };

  return <Pie data={data} options={options} />;
};

export default PieChart;
