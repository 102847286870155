import React, { useEffect, useState } from "react";
import SelectComponent from "../_components/common/SelectComponent";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startLoader, stopLoader } from "../_store/reducers/loaderReducer";
import { notifyError, notifySuccess } from "../_components/helper/Toaster";
import QuickNotif from "../_components/common/Notifications";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Button } from "react-bootstrap";
import Paginate from "../_components/UI/Pagination";
import SupplierModal from "../_components/UI/SupplierModal";
import { formatContact } from "../_components/helper/formatters";
import {
  addSupplierInfo,
  getSupplierInfo,
  searchSupplierInfo,
} from "../requests";
import { TOKEN_ERRORS } from "../_data/codes";
import handleSessionClear from "../_components/helper/handleSessionClear";

// STYLES
import styles from "../_css/Notifications.module.css";
import styles2 from "../_css/SupplierInfomation.module.css";

const SupplierInformation = () => {
  const [options, setOptions] = useState([]);
  const [suggestedData, setSuggestionData] = useState();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    totalPages: 2,
    totalPosts: 10,
  });
  const [sortedColumn, setSortedColumn] = useState({
    column: String,
    sortDsc: true,
  });
  const [showModal, setShowModal] = useState(false);
  const [sortingData, setSortingData] = useState(null);
  const [loadingSuggestion, setLoadingSuggestion] = useState(false);
  const [perPage, setPerPage] = useState(5);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [dataSorting, setDataSorting] = useState({
    createdAt: -1,
  });

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const access_token = localStorage.getItem("access_token_MN");
  const isSales =
    JSON.parse(localStorage.getItem("user_info_MN"))?.status === 3;

  const handleShowModal = (item) => {
    setShowModal(!showModal);
  };

  const handleContinue = async (formData) => {
    setShowModal(!showModal);
    // set notes to table
    await addSupplier(formData);
  };

  const handleSort = (key) => {
    let lowerKey = _.toLower(key);

    if (lowerKey === "name") lowerKey = "supplierName";
    else if (lowerKey === "s. code") lowerKey = "sCode";
    else if (lowerKey === "company name") lowerKey = "companyName";

    setDataSorting((prevValue) => ({
      [lowerKey]: prevValue[lowerKey] === 1 ? -1 : 1,
    }));

    // let previousLowerKey = !_.isEmpty(sortedColumn.column)
    //   ? _.toLower(sortedColumn.column)
    //   : null;

    // if (previousLowerKey === "name") previousLowerKey = "supplierName";
    // else if (previousLowerKey === "s. code") previousLowerKey = "sCode";
    // else if (previousLowerKey === "company name")
    //   previousLowerKey = "companyName";

    // let sortedData = data;
    // let coloumnChanged = false;

    // if (previousLowerKey && lowerKey !== previousLowerKey) {
    //   coloumnChanged = true;
    //   sortedData = [...data].sort((a, b) => {
    //     if (a[previousLowerKey] < b[previousLowerKey])
    //       return sortedColumn.sortDsc ? -1 : 1;
    //     if (a[previousLowerKey] > b[previousLowerKey])
    //       return sortedColumn.sortDsc ? 1 : -1;
    //     return 0;
    //   });
    // }

    // const sortingBasis = coloumnChanged ? coloumnChanged : sortedColumn.sortDsc;
    // sortedData = [...sortedData].sort((a, b) => {
    //   if (a[lowerKey] < b[lowerKey]) return sortingBasis ? -1 : 1;
    //   if (a[lowerKey] > b[lowerKey]) return sortingBasis ? 1 : -1;
    //   return 0;
    // });

    // setSortedColumn({ column: key, sortDsc: !sortingBasis });
    // setData(sortedData);
  };

  useEffect(() => {
    if (access_token) {
      getSupplier();
    } else {
      Navigate("/login");
    }
  }, [page, perPage, sortingData, dataSorting]);

  // GET SUGGESTION DATA FOR FILTER
  const getSupplier = async () => {
    dispatch(startLoader());

    let isSorting = false;

    // CHECKING IF SORTING APPLIED
    if (sortingData) {
      isSorting = true;
    }

    try {
      const payload = {
        page,
        limit: perPage || 5,
        isSorting,
        sortingData,
        dataSorting,
      };
      const response = await getSupplierInfo(payload);
      if (response.status === 200) {
        const { supplier, totalPages, totalSupplier, suggestedData } =
          response.data;

        const pageInfo = {
          totalPages: totalPages || 1,
          totalPosts: totalSupplier || 0,
        };

        setData(supplier);
        setPageInfo(pageInfo);
        setSuggestionData(suggestedData);
        // setAuthFalse();
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  // GET SUGGESTION DATA FOR FILTER
  const addSupplier = async (formData) => {
    dispatch(startLoader());

    try {
      const payload = formData;
      const response = await addSupplierInfo(payload);
      if (response.status === 200) {
        notifySuccess(response?.data?.message);
        await getSupplier();
        // setAuthFalse();
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleValueChange = async (value, column) => {
    if (value.length > 0 && column) {
      let values = [];
      for (const data of value) {
        values.push(data.value);
      }
      const reqData = {
        value: values,
        column: column,
      };

      setSortingData(reqData);
      // await getSupplier(true, reqData);
    } else {
      setSortingData(null);
    }
    handleResetPage();
  };

  const clearValueHandler = () => {
    handleResetPage();
    setOptions([]);
    setSortingData(null);
  };

  const handleNext = async (page) => {
    if (page + 1 <= pageInfo.totalPages) {
      setPage(page + 1);
      // setData(initialData.slice(5, 10));
    }
  };

  const handlePrevious = async (page) => {
    if (page - 1 > 0) {
      setPage(page - 1);
      // setData(initialData.slice(0, 5));
    }
  };

  const handleResetPage = () => {
    setPage(1);
  };

  const handlePerPage = (count) => {
    setPerPage(count);
  };

  // GET SUGGESTION DATA FOR FILTER
  const getSuggestionData = async (inputColumn, inputValue) => {
    if (_.isEmpty(inputColumn) || _.isEmpty(inputValue)) return;

    setLoadingSuggestion(true);
    try {
      const payload = {
        searchFor: inputColumn,
        searchString: inputValue,
      };
      const response = await searchSupplierInfo(payload);
      if (response.status === 200) {
        const { formattedData } = response.data;

        if (inputColumn === "location") {
          setSuggestionData((prevData) => {
            return {
              ...prevData,
              suggestionDataLocation: formattedData,
            };
          });
        } else {
          setSuggestionData((prevData) => {
            return {
              ...prevData,
              suggestionDataNames: formattedData,
            };
          });
        }
        // setAuthFalse();
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      setLoadingSuggestion(false);
    }
  };

  const onFocusHandler = async (column) => {
    clearValueHandler();
    setSelectedFilter(column);
    // if (column === "size") {
    //   if (!sortingData?.value || _.isEmpty(sortingData?.value)) {
    //     notifyInfo("Please select Description First!");
    //     return;
    //   }
    //   await getSuggestionData("size", alcoholInfo?.name, true);
    // } else if (column === "name") {
    //   await getSuggestionData(column);
    // }
  };

  const getCheveron = (key) => {
    return (
      <i>
        {Object.keys(dataSorting)[0] === key ? (
          dataSorting[key] === -1 ? (
            <ChevronUp size={16} />
          ) : (
            <ChevronDown size={16} />
          )
        ) : (
          <ChevronUp size={16} />
        )}
      </i>
    );
  };

  return (
    <div
      className={`container ml-5rem mt-4 ${styles["notifications-container"]}`}
    >
      <div className="position-relative">
        <div className={`position-relative mb-4 ${styles.filterSection}`}>
          <div className={styles.container}>
            <div className={`${styles.header} d-flex flex-row`}>
              <div className="col-3">
                <h1 className={styles.title}>Supplier Information</h1>
              </div>
              <div className={`${styles.filters} col-9`}>
                {/* <select className={styles.select}>
                <option>Name</option>
              </select> */}
                <SelectComponent
                  options={suggestedData?.suggestionDataNames}
                  placeholder={"Name"}
                  handleValueChange={handleValueChange}
                  getSuggestionData={getSuggestionData}
                  clearValueHandler={clearValueHandler}
                  inputColumn={"supplierName"}
                  loadingSuggestion={loadingSuggestion}
                  onFocusHandler={onFocusHandler}
                  selectedFilter={selectedFilter}
                />
                {/* <select className={styles.select}>
                <option>Location</option>
              </select> */}
                <SelectComponent
                  options={suggestedData?.suggestionDataLocation}
                  placeholder={"Location"}
                  handleValueChange={handleValueChange}
                  getSuggestionData={getSuggestionData}
                  clearValueHandler={clearValueHandler}
                  inputColumn={"location"}
                  loadingSuggestion={loadingSuggestion}
                  onFocusHandler={onFocusHandler}
                  selectedFilter={selectedFilter}
                />
                {/* <select className={styles.select}>
                <option>Date</option>
              </select> */}
                {/* <div className={styles.searchContainer}>
                <Search size={16} />
                <input
                  type="text"
                  className={styles.searchInput}
                  placeholder="Search"
                />
              </div> */}
                <QuickNotif />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`container bg-white rounded position-relative p-1 ${styles.table} ${styles2.table}`}
        >
          <div className="d-flex justify-content-between align-items-center m-3">
            <p className="m-0 fs-18 fw-600">Supplier list</p>
            <Button onClick={handleShowModal} variant="primary" size="sm">
              Add Supplier
            </Button>
          </div>
          <div style={{maxHeight: "340px"}} className="table-height-400">
            <table className={`table custom-table ${styles["border-top"]}`}>
              <thead>
                <tr>
                  <th
                    className="pe-cursor"
                    // style={{
                    //   minWidth: widthColoumns.includes(e) ? "98px" : "80px",
                    // }}
                    onClick={() => handleSort("Name")}
                  >
                    <div className={`${styles["col-value"]}`}>
                      <span>Name</span>
                      {getCheveron("supplierName")}
                    </div>
                  </th>
                  <th
                    className="pe-cursor"
                    // style={{
                    //   minWidth: widthColoumns.includes(e) ? "98px" : "80px",
                    // }}
                    onClick={() => handleSort("Contact")}
                  >
                    <div className={`${styles["col-value"]}`}>
                      <span>Contact</span>
                      {/* <i>
                      {sortedColumn.column === "Contact" ? (
                        sortedColumn.sortDsc ? (
                          <ChevronUp size={16} />
                        ) : (
                          <ChevronDown size={16} />
                        )
                      ) : (
                        <ChevronUp size={16} />
                      )}
                    </i> */}
                    </div>
                  </th>
                  <th
                    className="pe-cursor"
                    // style={{
                    //   minWidth: widthColoumns.includes(e) ? "98px" : "80px",
                    // }}
                    onClick={() => handleSort("S. Code")}
                  >
                    <div className={`${styles["col-value"]}`}>
                      <span>S. Code</span>
                      {getCheveron("sCode")}
                    </div>
                  </th>
                  <th
                    className="pe-cursor"
                    // style={{
                    //   minWidth: widthColoumns.includes(e) ? "98px" : "80px",
                    // }}
                    onClick={() => handleSort("Company Name")}
                  >
                    <div className={`${styles["col-value"]}`}>
                      <span>Company Name</span>
                      {getCheveron("companyName")}
                    </div>
                  </th>
                  <th
                    className="pe-cursor"
                    // style={{
                    //   minWidth: widthColoumns.includes(e) ? "98px" : "80px",
                    // }}
                    onClick={() => handleSort("Location")}
                  >
                    <div className={`${styles["col-value"]}`}>
                      <span>Location</span>
                      {getCheveron("location")}
                    </div>
                  </th>
                  <th
                    className="pe-cursor"
                    // style={{
                    //   minWidth: widthColoumns.includes(e) ? "98px" : "80px",
                    // }}
                    onClick={() => handleSort("Email")}
                  >
                    <div className={`${styles["col-value"]}`}>
                      <span>Email</span>
                      {getCheveron("email")}
                    </div>
                  </th>
                  <th scope="col">Offer</th>
                </tr>
              </thead>
              <tbody>
                {data.map((supplier, index) => (
                  <tr key={index}>
                    <td>{supplier?.supplierName || "Not Found"}</td>
                    <td>{formatContact(supplier?.contact || 6845550102)}</td>
                    <td>{supplier?.sCode || "Not Found"}</td>
                    <td>{supplier?.companyName || "Not Found"}</td>
                    <td>{supplier?.location || "Not Found"}</td>
                    <td>{supplier?.email || "Not Found"}</td>
                    <td>
                      <div
                        onClick={() =>
                          Navigate(
                            `/supplier-informations/${supplier?.sCode}`,
                            {
                              state: { supplierCode: supplier?.sCode },
                            }
                          )
                        }
                        className={styles2.statusContainer}
                      >
                        <span
                          key={index}
                          className={`fw-500 pe-cursor ${styles2.status} ${styles2["view-offers"]}`}
                        >
                          View Offers
                        </span>
                      </div>
                    </td>
                    {/* <td>{formatDate(supplier.createdAt)}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div style={{ bottom: "-70px" }} className="position-absolute w-100">
          <Paginate
            currentPage={page}
            pageInfo={pageInfo}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handlePerPage={handlePerPage}
            resetPage={handleResetPage}
          />
        </div>
      </div>
      <SupplierModal
        showModal={showModal}
        handleShowModal={handleShowModal}
        handleContinue={handleContinue}
        title={"Add Supplier"}
        type={isSales ? "sales" : "admin"}
      />
    </div>
  );
};

export default SupplierInformation;
