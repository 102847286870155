import React from "react";
import { Link } from "react-router-dom";

function LogoutPage() {
  return (
    <div
      style={{
        height: "90vh",
      }}
      className="text-center d-flex justify-content-center flex-column align-items-center"
    >
      Logging Out...
      <p className="fs-11">
        if not redirected automatically please
        <Link to="/">
          <i> click here...</i>
        </Link>
      </p>
    </div>
  );
}

export default LogoutPage;
