const formatDate = (stringDate, withTime = false) => {
  const date = new Date(stringDate);
  // Format the date object into the desired format (DD/MM/YYYY)

  const dateFormat = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  if (withTime) {
    dateFormat["hour"] = "2-digit";
    dateFormat["minute"] = "2-digit";
  }

  const formattedDate = date.toLocaleDateString("en-GB", dateFormat);
  return formattedDate;
};

const formatContact = (number) => {
  // Convert the number to a string
  let str = number.toString();

  // Ensure the string is 10 digits long
  if (str.length !== 10) {
    str = "6845550102";
  }

  // Format the string
  const formatted = `(${str.slice(0, 3)}) ${str.slice(3, 6)}-${str.slice(6)}`;

  return formatted;
};

function numbersFormatter(str) {
  if (!str || typeof str !== "string") {
    return "N/A";
  }
  // Remove leading/trailing whitespace
  str = str.trim();

  // Check if empty string
  if (str === "") {
    return "N/A";
  }

  // Attempt conversion to number using parseFloat
  const number = parseFloat(str);

  // Check if conversion was successful (not NaN)
  if (isNaN(number)) {
    return "N/A";
  }

  // Return the number with two decimal places
  return number.toFixed(2);
}

function getFixedNumber(str) {
  // Remove leading/trailing whitespace
  let number = str;
  if (typeof str === "string") {
    str = str.trim();
    // Check if empty string
    if (str === "") {
      return 0;
    }
    // Attempt conversion to number using parseFloat
    number = parseFloat(str);
  }

  // Check if no value
  if (!number) {
    return 0;
  }

  // Check if conversion was successful (not NaN)
  if (isNaN(number)) {
    return 0;
  }

  // Return the number with two decimal places
  return number.toFixed(2);
}

const getDollarValue = (value, currency, conversionRate) => {
  let res = "-";
  const finalValue = numbersFormatter(value);
  if (finalValue !== "N/A") {
    if (currency === "USD") {
      res = Number(finalValue).toFixed(2);
    } else if (currency === "GBP") {
      res = (finalValue * conversionRate?.poundForDollar).toFixed(2);
    } else if (currency === "EUR") {
      res = (finalValue * conversionRate?.euroForDollar).toFixed(2);
    }
  }
  return res;
};

const getPoundValue = (value, currency, conversionRate) => {
  let res = "-";
  const finalValue = numbersFormatter(value);
  if (finalValue !== "N/A") {
    if (currency === "USD") {
      res = (finalValue * conversionRate?.dollarForPound).toFixed(2);
    } else if (currency === "GBP") {
      res = Number(finalValue).toFixed(2);
    } else if (currency === "EUR") {
      res = (finalValue * conversionRate?.euroForPound).toFixed(2);
    }
  }
  return res;
};

const getEuroValue = (value, currency, conversionRate) => {
  let res = "-";
  const finalValue = numbersFormatter(value);
  if (finalValue !== "N/A") {
    if (currency === "USD") {
      res = (finalValue * conversionRate?.dollarForEuro).toFixed(2);
    } else if (currency === "EUR") {
      res = Number(finalValue).toFixed(2);
    } else if (currency === "GBP") {
      res = (finalValue * conversionRate?.poundForEuro).toFixed(2);
    }
  }
  return res;
};

const getAbvValue = (value) => {
  if (value && typeof value === "string") {
    const num = Number(value);
    return isNaN(num) ? "-" : `${num}%`;
  } else {
    return "-";
  }
};

function truncateString(str) {
  if (str.length > 25) {
    return str.substring(0, 25) + "...";
  } else {
    return str;
  }
}

export {
  formatDate,
  formatContact,
  numbersFormatter,
  getDollarValue,
  getPoundValue,
  getEuroValue,
  getFixedNumber,
  getAbvValue,
  truncateString,
};
