// Define initial state
const initialState = {
    fullName: '',
    userId: '',
    email: '',
    status: 0,
  };
  
  // Define action types
  const SET_USER_INFORMATION = 'SET_USER_INFORMATION';
  const REMOVE_USER_INFORMATION = 'REMOVE_USER_INFORMATION';
  
  // Define reducer function
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_USER_INFORMATION:
        return {
          ...state,
          fullName: action.payload.fullName,
          userId: action.payload.userId,
          email: action.payload.email,
          status: action.payload.status
        };
      case REMOVE_USER_INFORMATION:
        return initialState;
      default:
        return state;
    }
  };


// Action creators
const setUserInformation = (fullName, userId, email, status) => ({
  type: SET_USER_INFORMATION,
  payload: { fullName, userId, email, status }
});

const removeUserInformation = () => ({
  type: REMOVE_USER_INFORMATION
});

export { setUserInformation, removeUserInformation };
export default userReducer;
  