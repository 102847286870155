import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import userReducer from './reducers/userReducer';
import loaderReducer from './reducers/loaderReducer';
import notificationReducer from './reducers/notificationReducer';
import pageReducer from './reducers/pageReducer';
import preserveStateReducer from './reducers/preserveStateReducer';

// Combine reducers if you have multiple reducers
const rootReducer = combineReducers({
    user: userReducer, // Add the user reducer to the root reducer
    loader: loaderReducer,
    notification: notificationReducer,
    page: pageReducer,
    preserveState: preserveStateReducer,
    // Add other reducers here if needed
  });

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
