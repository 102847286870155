import React, { useEffect, useState } from "react";
import Select from "react-select";
import Pagination from "react-bootstrap/Pagination";

const PER_PAGE = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "24px", // Decrease this value to reduce the height
    height: "24px", // Ensure the height is consistently set
    borderColor: state.isFocused ? "#ccc" : "#ccc",
    boxShadow: state.isFocused ? null : null,
    fontSize: "11px", // Adjust font size here
    "&:hover": {
      borderColor: "#ccc",
    },
  }),
  SingleValue: (provided) => ({
    ...provided,
    fontSize: "11px", // Adjust font size here
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: "0 8px",
    height: "24px",
    fontSize: "11px", // Adjust font size here
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
    padding: "0",
    fontSize: "11px", // Adjust font size here
    width: "20px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "24px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#ccc" : "#ccc",
    "&:hover": {
      color: "#ccc",
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: "50px", // Fixed width of the dropdown menu
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "300px", // Fixed height of the dropdown menu
    overflowY: "auto", // Enable scrolling if the content exceeds the max height
  }),
};

export default function Paginate(props) {
  const [selectedOption, setSelectedOption] = useState({value: 0, label: 0});

  useEffect(() => {
    if (!selectedOption?.value) {
      if (props?.pageInfo?.totalPosts < 5) {
        const totalPosts = props?.pageInfo?.totalPosts;
        setSelectedOption({
          value: totalPosts,
          label: totalPosts,
        });
      } else {
        setSelectedOption({ value: 5, label: 5 });
      }
    }
  }, [props]);

  const handleChange = (option) => {
    if (!option) {
      setSelectedOption({ value: 5, label: 5 });
    }
    props.resetPage()
    props.handlePerPage(option.value);
    setSelectedOption(option);
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex gap-3">
        Showing:
        <Select
          value={selectedOption}
          onChange={handleChange}
          options={PER_PAGE}
          styles={customStyles}
        />{" "}
        of {props?.pageInfo?.totalPosts} results.
      </div>
      <Pagination>
        <Pagination.Prev
          onClick={() => props.handlePrevious(props.currentPage)}
        />
        {/* <Pagination.Ellipsis /> */}
        <Pagination.Item onClick={() => props.handlePrevious(2)}>
          {1}
        </Pagination.Item>
        <Pagination.Ellipsis />
        <Pagination.Item className="activePage">
          {props.currentPage}
        </Pagination.Item>
        <Pagination.Ellipsis />
        <Pagination.Item
          onClick={() => props.handleNext(props.pageInfo.totalPages - 1)}
        >
          {props.pageInfo.totalPages}
        </Pagination.Item>

        <Pagination.Next onClick={() => props.handleNext(props.currentPage)} />
      </Pagination>
    </div>
  );
}
