import React, { useEffect } from "react";
import { PageLayout } from "../_components/PageLayout";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const Navigate = useNavigate();
  const access_token = localStorage.getItem("access_token_MN");

  // IF USER IS AUTHENTICATED THEN LOG THEM IN
  useEffect(() => {
    if (access_token) {
      Navigate("/");
    }
  }, []);

  return (
    <>
      <PageLayout />
    </>
  );
}
