import React from "react";
import { useLocation } from "react-router-dom";
import styles from "../_css/SupplierDetails.module.css";
import Notifications from "../_components/common/Notifications";
import SupplierDetail from "../_components/UI/SupplierDetail";

function SupplierDetails() {
  const location = useLocation();
  const supplierCode = location.state?.supplierCode;

  return (
    <div className={`container ml-5rem mt-4 ${styles["supplier-container"]}`}>
      <div className="position-relative">
        <div className={`position-relative mb-4 ${styles.filterSection}`}>
          <div className={styles.container}>
            <div className={`${styles.header} d-flex flex-row`}>
              <div className="col-3">
                <h1 className={styles.title}>Supplier Information</h1>
              </div>
              <div className={`${styles.filters} col-9`}>
                {/* <select className={styles.select}>
                <option>Name</option>
              </select> */}
                {/* <SelectComponent
                  options={options}
                  placeholder={"Name"}
                  handleValueChange={handleValueChange}
                  getSuggestionData={getSuggestionData}
                  clearValueHandler={clearValueHandler}
                  inputColumn={"supplierName"}
                /> */}
                {/* <select className={styles.select}>
                <option>Location</option>
              </select> */}
                {/* <SelectComponent
                options={options}
                placeholder={"Category"}
                handleValueChange={handleValueChange}
                getSuggestionData={getSuggestionData}
                clearValueHandler={clearValueHandler}
                inputColumn={"category"}
              /> */}
                {/* <select className={styles.select}>
                <option>Date</option>
              </select> */}
                {/* <div className={styles.searchContainer}>
                <Search size={16} />
                <input
                  type="text"
                  className={styles.searchInput}
                  placeholder="Search"
                />
              </div> */}
                <Notifications />
              </div>
            </div>
          </div>
        </div>
        <SupplierDetail sCode={supplierCode}/>
      </div>
    </div>
  );
}

export default SupplierDetails;
