import toast from "react-hot-toast";

const notifySuccess = (msg) => toast.success(msg);

const notifyError = (msg) => toast.error(msg);

const notifyInfo = (msg) =>
  toast(msg, {
    icon: "❗",
  });

export { notifyError, notifySuccess, notifyInfo };
