import React from "react";
import styles from "../../_css/Pending.module.css";

function Blocked() {
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${styles.container}`}
    >
      <div className={`${styles["fixed-width"]} card text-center p-4 shadow`}>
        <div className={`mb-4 ${styles.iconWrapper}`}>
          <img
            src={`${process.env.REACT_APP_PROJECT_URL}/img/cross-img.jpg`}
            alt="Checkmark"
            className={`${styles.icon}`}
          />
        </div>
        <h1 className="h4 fw-700">Your profile is blocked.</h1>
      </div>
    </div>
  );
}

export default Blocked;
