import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles from "../../_css/NotesModal.module.css";
import { notifyError } from "../helper/Toaster";
import _ from "lodash";

function NotesModal(props) {
  const [show, setShow] = useState(props?.showModal);
  const [notes, setNotes] = useState(props?.note);
  const [action, setAction] = useState(props?.selectedAction);
  const [qty, setQty] = useState(null);

  useEffect(() => {
    setShow(props?.showModal);
    setAction(props?.selectedAction);
    setNotes(props?.note);
    setQty(props?.quantity);
  }, [props]);

  const handleOnNotesChange = (event) => {
    const value = event.target.value;
    if (value.length < 256) {
      setNotes(value);
    }
  };

  const handleOnQtyChange = (event) => {
    const value = event.target.value;
    if (value > 0) {
      setQty(value);
    }
  };

  const handleContinue = (action) => {
    if (qty && typeof action === "object") {
      action["quantity"] = qty;
    }
    if (props?.type === "admin") {
      if (_.isEmpty(notes)) {
        notifyError("Notes can't be empty.");
        return;
      }
    } else {
      if (_.isEmpty(notes)) {
        notifyError("Notes can't be empty.");
        return;
      } else if (_.isEmpty(qty)) {
        notifyError("Number of Cases is required.");
        return;
      }
      action["quantity"] = qty;
    }
    props.handleContinue(notes, action, false);
  };

  // const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={props.handleShowModal} centered>
        {/* <Modal.Header className="m-auto">
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header> */}
        <Modal.Body
          className={`gap-5px mx-1 d-flex justify-content-center flex-column ${styles["notes-container"]}`}
        >
          <label className="fw-500 fs-22" for="notesField">
            Add Notes{" "}
          </label>
          <textarea
            type="text"
            id="notesField"
            className={styles.searchInput}
            placeholder="Enter Notes"
            value={notes}
            onChange={handleOnNotesChange}
            rows={5}
          />
          {props?.type === "sales" && (
            <div className="form-group d-flex flex-column gap-5px mt-1">
              <label className="fw-500 fs-22" for="quantityField">
                Number of Cases{" "}
              </label>
              <input
                type="number"
                className={`form-control ${styles.qtyInput}`}
                id="quantityField"
                aria-describedby="quantity"
                placeholder="Its compulsory to add Number of Cases"
                onChange={handleOnQtyChange}
                value={qty}
              />
              {/* <small id="emailHelp" class="form-text text-muted">
              We'll never share your email with anyone else.
            </small> */}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button
            size="sm"
            className={`${styles["btn-cancel"]}`}
            variant="outline-secondary"
            onClick={props.handleShowModal}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="primary"
            className={`${styles["btn-continue"]}`}
            onClick={() => handleContinue(action)}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NotesModal;
