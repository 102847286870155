// Define initial state
const initialState = {
  unreadNotifications: 0,
  notifications: [],
};

// Define action types
const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
const SET_UNREAD_NOTIFICATION_COUNT = "SET_UNREAD_NOTIFICATION_COUNT";

// Define reducer function
const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        unreadNotifications: action.payload.unreadNotification,
        notifications: action.payload.notification,
      };
    case SET_UNREAD_NOTIFICATION_COUNT:
      return {
        ...state,
        unreadNotifications: action.payload.unreadCount,
      };
    default:
      return state;
  }
};

// Action creators
const setNotifications = (payload) => ({
  type: SET_NOTIFICATIONS,
  payload: payload,
});

const setUnreadNotifications = (payload) => ({
  type: SET_UNREAD_NOTIFICATION_COUNT,
  payload: payload,
});

export { setNotifications, setUnreadNotifications };
export default notificationReducer;
